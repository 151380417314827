/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import useInterval from 'hooks/use-interval';

import { CALLBACK_URL } from './constants';
import { AuthContext } from './context';
import { Tokens, User } from './interfaces';
import {
  clearTokensFromStorage,
  convertResponseToTokens,
  evaluateTokenFreshness,
  extractCodeFromLocation,
  extractUserFromTokens,
  getOrGeneratePkceChallenge,
  locationContainsCode,
  readTokensFromStorage,
  saveTokensToStorage,
} from './utils';

const DO_DEBUG = false;

function debug(...args: any[]) {
  if (DO_DEBUG) {
    console.log('AuthProvider:', ...args);
  }
}

/**
 * Auth provider props
 */
export interface AuthProviderProps {
  authority: string;
  redirectUri: string;
  logoutUri: string;
  tokenUri: string;
  clientId: string;
  location: Location;
  scopes?: string;
  tokens?: Tokens | null;
  warningPeriod?: number;
  inactivityPeriod?: number;
  onLogout?: () => void;
  onLogin?: () => void;
}

/**
 * Auth context provider
 *
 * Any changes to this file will create a lot of testing scenarios. Use
 * caution with edits to this file, and make sure you alert the team that
 * changes are made so we know to test extra-carefully.
 */
export const AuthProvider: React.FC<AuthProviderProps> = ({
  authority,
  clientId,
  children,
  logoutUri,
  redirectUri,
  tokenUri,
  scopes,
  warningPeriod = 300000,
  inactivityPeriod = 14400000, // 4 hours
  onLogin = () => {},
  onLogout = () => {},
}) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [tokens, setTokens] = useState<Tokens | null>(null);
  const [expiringSoon, setExpiringSoon] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  debug('Start auth provider');

  const redirectToLogin = useCallback(
    (clear = false) => {
      debug('Redirect to login');

      const { challenge } = getOrGeneratePkceChallenge(sessionStorage);
      const url = new URL(`${authority}${redirectUri}`);

      url.searchParams.append('client_id', clientId);
      url.searchParams.append('response_type', 'code');
      url.searchParams.append('scope', scopes || 'openid profile');
      url.searchParams.append('code_challenge', challenge);
      url.searchParams.append('code_challenge_method', 'S256');
      url.searchParams.append('redirect_uri', CALLBACK_URL.toString());
      url.searchParams.append('state', window.location.pathname || '/');

      if (onLogin) {
        onLogin();
      }

      if (clear) {
        clearTokensFromStorage(localStorage, false);
      }

      window.location.href = url.toString();
    },
    [authority, redirectUri, onLogin, clientId, scopes]
  );

  const handleLogout = useCallback(() => {
    debug('Logout');
    const localTokens = readTokensFromStorage(localStorage);

    if (!authenticated || localTokens === null) {
      return;
    }
    const url = new URL(`${authority}${logoutUri}`);

    url.searchParams.append('prompt', 'none');
    url.searchParams.append('id_token_hint', localTokens?.idToken || '');
    url.searchParams.append(
      'post_logout_redirect_uri',
      CALLBACK_URL.toString()
    );

    clearTokensFromStorage(localStorage, true);
    clearTokensFromStorage(sessionStorage, true);

    if (onLogout) {
      onLogout();
    }

    window.location.href = url.toString();
  }, [authenticated]);

  const handleUserUpdate = useCallback((input: User) => {
    debug('User update', user);
    setUser({ ...user, ...input });
  }, []);

  const handleTokenRefresh = useCallback((newTokens: Tokens) => {
    debug('Token refresh', tokens);
    setTokens(newTokens);
    setExpiringSoon(false);
    saveTokensToStorage(localStorage, newTokens);
  }, []);

  // Code and initial login sensing
  useEffect(() => {
    let fetching = false;
    debug('Code and initial login sensing');

    if (locationContainsCode(window.location)) {
      fetching = true;
      const { code, state } = extractCodeFromLocation(window.location);
      const { verifier } = getOrGeneratePkceChallenge(sessionStorage);
      const url = new URL(`${authority}${tokenUri}`);
      const params = new URLSearchParams();

      params.append('client_id', clientId);
      params.append('grant_type', 'authorization_code');
      params.append('state', state);
      params.append('code', code || '');
      params.append('code_verifier', verifier);
      params.append('redirect_uri', CALLBACK_URL.toString());

      debug('Fetching tokens', params.toString());

      fetch(url.toString(), {
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (!res.ok) {
            debug('Fetching tokens failed', res.statusText);
            throw new Error(`Refresh error: ${res.statusText}`);
          }

          return res.json();
        })
        .then((data) => {
          debug('Fetching tokens success', data);
          const newTokens: Tokens = convertResponseToTokens({ ...data, state });
          saveTokensToStorage(localStorage, newTokens);
          clearTokensFromStorage(sessionStorage, false);
          setTokens(newTokens);
          setUser({
            ...(user || {}),
            ...extractUserFromTokens(newTokens),
          });
          setAuthenticated(true);
        })
        .catch(handleLogout);
    }

    // We need to wait and see if we get a code, before
    // we start checking for them.
    if (fetching) {
      return;
    }

    const localTokens = readTokensFromStorage(localStorage);
    const { expired } = evaluateTokenFreshness(
      localTokens,
      warningPeriod,
      inactivityPeriod
    );

    // If we have no tokens at all, or if the access token is expired AND
    // there is no refresh token... we need to exit promptly.
    if (localTokens === null || (expired && !localTokens?.refreshToken)) {
      return redirectToLogin();
    }

    setTokens(localTokens);
    setUser({
      ...(user || {}),
      ...extractUserFromTokens(localTokens),
    });
    setAuthenticated(true);
  }, []);

  // Refresh sensing every 5 seconds.
  useInterval(() => {
    debug('Refresh check');
    const localTokens = readTokensFromStorage(localStorage);
    const { passedDeadline, aboutToExpire } = evaluateTokenFreshness(
      localTokens,
      warningPeriod,
      inactivityPeriod
    );

    if (passedDeadline || (aboutToExpire && !localTokens?.refreshToken)) {
      debug('Refresh check failed');
      clearTokensFromStorage(localStorage, false);
      clearTokensFromStorage(sessionStorage, false);
      return redirectToLogin();
    }

    if (aboutToExpire !== expiringSoon) {
      debug('Expiring soon', aboutToExpire);
      setExpiringSoon(aboutToExpire);
    }
  }, 5000);

  return (
    <AuthContext.Provider
      value={{
        login: redirectToLogin,
        logout: handleLogout,
        refreshToken: handleTokenRefresh,
        updateUser: handleUserUpdate,
        authenticated,
        expiringSoon,
        tokens,
        user,
      }}
    >
      {authenticated ? children : null}
    </AuthContext.Provider>
  );
};
