const INTERVAL_TO_DATE_PERIOD_MAP = new Map<Bera.DateInterval, Bera.DateGrain>([
  ['weekly', 'weekly'],
  ['monthly', 'monthly'],
  ['quarterly', 'quarterly'],
  ['yearly', 'annually'],
]);

const DEFAULT: Bera.DateGrain = 'annually';

export default function getGrainByInterval(
  interval: Bera.DateInterval = 'yearly'
) {
  const grain = INTERVAL_TO_DATE_PERIOD_MAP.get(interval) || DEFAULT;
  return grain;
}
