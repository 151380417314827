import { createContext } from 'react';

import noop from 'utils/noop';

export interface NotifierState {
  notifications: Bera.WholeNotification[];
  clear(channel?: Bera.NotificationChannel): void;
  notify(notification: Bera.Notification): Bera.WholeNotification;
  notificationsByChannel<C extends Bera.NotificationChannel>(
    id: C
  ): Bera.WholeNotification<C>[];
  remove: (id: string) => void;
}

const NotifierContext = createContext<NotifierState>({
  notifications: [],
  clear: noop,
  notify: () => {
    throw new Error('NotifierContext.notify() must be overridden');
  },
  notificationsByChannel: () => [],
  remove: noop,
});

export default NotifierContext;
