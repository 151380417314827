import React from 'react';

import { Redirect } from 'react-router-dom';

import useLaunchpad from 'contexts/launchpad';

import determineDefaultApp from './determine-default-app';

const Home: React.FC<any> = () => {
  const { allowedApplications, lastUsedApp, loading } = useLaunchpad();

  if (loading) {
    return null;
  }

  const fallback = determineDefaultApp(allowedApplications, lastUsedApp);

  return <Redirect to={fallback} />;
};

export default Home;
