import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';

import { findAllowedApps } from 'utils/application';

const GET_LAUNCHPAD_SETUP_DATA = gql`
  query GetLaunchpadSetupData {
    activeSubscriptions {
      id
      from
      grain
      label
      productRoles
      datasets {
        id
        countryId
        countryCode
        label
        studies
        lowestGrain
        grainDates {
          key
          start
          end
        }
      }
      studies {
        audience {
          id
          label
        }
        countryIds
        countries {
          id
          studyId
          code
          label
        }
      }
      to
    }
    lastUsedApp: userPreference(category: "bang", key: "last-app") {
      value
    }
    userInfo {
      email
      domoid
      entitlements
      familyName
      fullName
      givenName
      groups
      roles
      sub
      subscriptions
      theme {
        primary
        secondary
      }
    }
    applications {
      id
      iamRoles
      logo
      name
      url
    }
  }
`;

interface GetSubscriptionsResponse {
  activeSubscriptions: Bera.Subscription[];
  lastUsedApp: { value: string } | null;
  userInfo: Bera.UserInfo & { theme: Bera.Theme | null };
  applications: Bera.Application[];
}

function useLaunchpadData() {
  const { data, ...rest } = useQuery<GetSubscriptionsResponse>(
    GET_LAUNCHPAD_SETUP_DATA,
    { errorPolicy: 'all' }
  );

  return useMemo(() => {
    const subscriptions = data?.activeSubscriptions || [];
    const lastUsedApp = data?.lastUsedApp?.value || null;
    const userInfo = data?.userInfo;
    const roles = userInfo?.roles || [];
    const applications = data?.applications || [];
    const allowedApplications = findAllowedApps(applications, roles);

    return {
      data,
      allowedApplications,
      applications,
      lastUsedApp,
      subscriptions,
      roles,
      userInfo,
      ...rest,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
}

export default useLaunchpadData;
