import getDefaultState from './get-default-state';

export default function getDefaultsFromSubscription(
  sub: Bera.Subscription,
  parse = false
) {
  const dataset = sub.datasets[0];
  const defaults = getDefaultState(sub, dataset);
  defaults.subscription = sub.id;

  if (parse) {
    if (dataset) {
      defaults.country = dataset.countryId;
      defaults.countryCode = dataset.countryCode;
      defaults.dataset = dataset.id;
      defaults.studies = dataset.studies;
    }
  }

  return defaults;
}
