import React from 'react';

import { Typography } from '@material-ui/core';

import { Base } from './base';

export interface UnauthorizedProps {
  app?: string;
}

export const Unauthorized: React.FC<UnauthorizedProps> = ({
  app = 'this application',
}) => {
  const description = (
    <Typography>You are not authorized to access {app}.</Typography>
  );

  return <Base title="Unauthorized Access." description={description} />;
};

export default Unauthorized;
