import formatUtcDate from '../dates/format-utc-date';
import getDefaultAnalysisPeriodForSubscription from './analysis-period/get-default-analysis-period-for-subscription';

export default function getDefaultState(
  subscription?: Bera.Subscription,
  dataset?: Bera.Dataset | Bera.SubscriptionDataset
) {
  const {
    analysisPeriodStart,
    analysisPeriodEnd,
    analysisPeriodValue,
    analysisPeriodInterval,
  } = getDefaultAnalysisPeriodForSubscription(subscription, dataset);

  return {
    revision: 0,
    mode: 'brand',
    analysisPeriodStart,
    utcAnalysisPeriodStart: formatUtcDate(analysisPeriodStart),
    analysisPeriodEnd,
    utcAnalysisPeriodEnd: formatUtcDate(analysisPeriodEnd),
    analysisPeriodInterval,
    analysisPeriodValue,
    subscription: undefined,
    country: undefined,
    countryCode: undefined,
    dataset: undefined,
    studies: [],
    audience: '0',
    primary: undefined,
    competitiveSet: [],
    competitiveAudiences: [],
  } as Bera.GlobalState;
}
