import React from 'react';

import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Base } from './base';

const NotFound: React.FC = () => {
  const description = (
    <Typography>
      The page you are looking for could not be found. Try going back to the{' '}
      <Link to="/">home page</Link> to find what you're looking for.
    </Typography>
  );

  return <Base title="Page not found." description={description} />;
};

export default NotFound;
