import { createContext } from 'react';

import noop from 'utils/noop';

export interface ZendeskApi {
  initialized: boolean;
  clear: () => void;
  close: () => void;
  execute: (command: string, ...args: any[]) => void;
  logout: () => void;
  open: () => void;
  reset: () => void;
  search: (suggestion: string) => void;
  searchLabel: (labels: string[]) => void;
  toggle: () => void;
}

const ZendeskContext = createContext<ZendeskApi>({
  initialized: false,
  clear: noop,
  close: noop,
  execute: noop,
  logout: noop,
  open: noop,
  reset: noop,
  search: noop,
  searchLabel: noop,
  toggle: noop,
});

export default ZendeskContext;
