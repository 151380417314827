import * as React from 'react';

const NorthStar: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 483.6 483.6"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M483.6 241.8C350 241.8 241.8 133.5 241.8 0c0 133.5-108.2 241.8-241.8 241.8 133.5 0 241.8 108.2 241.8 241.8 0-133.6 108.2-241.8 241.8-241.8z"
        fill={color}
      />
    </svg>
  );
};

NorthStar.defaultProps = {
  color: '#ff2e36',
};

export { NorthStar };
export default NorthStar;
