import React, { useMemo } from 'react';

import {
  CssBaseline,
  PaletteType,
  ThemeProvider,
  alpha,
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
  useMediaQuery,
} from '@material-ui/core';

import colors from 'data/colors';
import defaultTheme from 'data/default-theme';

import useLaunchpad from '../launchpad';
import { GlobalStyles } from './global-styles';

function simpleThemeToPalette({
  primary: x = null,
  secondary: y = null,
  ...rest
}: Bera.SimpleTheme) {
  return {
    ...(x && { primary: { main: x } }),
    ...(y && { secondary: { main: y } }),
    ...rest,
  };
}

const defaultFontFamily = [
  'IBM Plex Sans',
  'Roboto',
  'Helvetica Neue',
  '-apple-system',
  'sans-serif',
];
const defaultFontFamilyText = defaultFontFamily.join(', ');

function setupTheme(darkMode: boolean, custom: Bera.SimpleTheme = {}) {
  const userPalette = simpleThemeToPalette(custom);

  return {
    palette: {
      type: darkMode ? ('dark' as PaletteType) : ('light' as PaletteType),
      primary: { main: colors.beraBlue },
      secondary: { main: colors.strawberry.primary },
      divider: darkMode ? 'hsla(0, 0%, 100%, 0.12)' : colors.neutral[6],
      tonalOffset: 0.2,
      ...userPalette,
    },
    // I would like to remove these custom shadows. They're not a good idea,
    // but product insisted.
    shadows: [
      'none',
      '0 0 0 0 hsla(0, 0%, 0%, 0)',
      '0 2px 8px 0 hsla(0, 0%, 0%, 0.04)',
      '0 1px 2px 0 hsla(0, 0%, 0%, 0.02), 0 4px 12px 0 hsla(0, 0%, 0%, 0.05)',
      '0 2px 2px 0 hsla(0, 0%, 0%, 0.05), 0 4px 20px 0 hsla(0, 0%, 0%, 0.08)',
      '0 2px 2px 0 hsla(0, 0%, 0%, 0.03), 0 2px 8px 0 hsla(0, 0%, 0%, 0.04)',
      '0 2px 2px 0 hsla(0, 0%, 0%, 0.03), 0 4px 12px 0 hsla(0, 0%, 0%, 0.07)',
      '0 4px 4px 0 hsla(0, 0%, 0%, 0.02), 0 10px 16px 0 hsla(0, 0%, 0%, 0.08)',
      '0 2px 12px 0 hsla(0, 0%, 0%, 0.07), 0 10px 20px 0 hsla(0, 0%, 0%, 0.1)',
    ],
    typography: {
      fontFamily: defaultFontFamilyText,
    },
    overrides: {
      MuiButtonBase: {
        root: {
          '& svg': {
            fontSize: '1.15rem',
          },
        },
      },
      MuiListItem: {
        root: {
          '&$selected': {
            backgroundColor: darkMode
              ? alpha(colors.blue.primary!, 0.15)
              : colors.blue[12],
          },
          '&$selected:hover': {
            backgroundColor: darkMode
              ? alpha(colors.blue.primary!, 0.08)
              : colors.blue[13],
          },
          '&$focusVisible': {
            backgroundColor: darkMode
              ? alpha(colors.blue.primary!, 0.2)
              : colors.blue[11],
          },
        },
        button: {
          '&:hover': {
            backgroundColor: darkMode
              ? alpha(colors.blue.primary!, 0.08)
              : colors.blue[13],
          },
        },
      },
      MuiMenuItem: {
        root: {
          '&$selected': {
            backgroundColor: darkMode
              ? alpha(colors.blue.primary!, 0.15)
              : colors.blue[12],
          },
          '&$selected:hover': {
            backgroundColor: darkMode
              ? alpha(colors.blue.primary!, 0.08)
              : colors.blue[13],
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: darkMode ? colors.transparent : colors.white,

          '&$focused': {
            backgroundColor: darkMode ? colors.transparent : colors.white,
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: darkMode ? 'hsl(0, 0%, 50%)' : 'hsl(0, 0%, 30%)',
        },
      },
    },
  };
}

interface CommonMuiThemeProps {
  forceLightMode?: boolean;
  customTheme?: Bera.SimpleTheme;
}

export const CommonMuiTheme: React.FC<CommonMuiThemeProps> = ({
  forceLightMode = false,
  customTheme,
  ...props
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const useDarkMode = forceLightMode ? false : prefersDarkMode;
  const theme = useMemo(() => {
    return responsiveFontSizes(
      createMuiTheme(setupTheme(useDarkMode, customTheme || {}) as any)
    );
  }, [useDarkMode, customTheme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {props.children}
    </ThemeProvider>
  );
};

export const Theme: React.FC = (props) => {
  const { userInfo } = useLaunchpad();

  if (!userInfo) {
    return null;
  }

  const custom = userInfo?.theme || defaultTheme;

  return <CommonMuiTheme customTheme={custom}>{props.children}</CommonMuiTheme>;
};
