import noop from 'utils/noop';

interface GainsightOptions {
  apiKey: string;
}

function isScriptLoaded(doc: any) {
  const scripts = doc.getElementsByTagName('script');
  const matcher = 'gainsight';

  return Object.keys(scripts).some((key) => {
    const info = scripts[key] || {};
    const src = info.src || '';

    return src.includes(matcher);
  });
}

export default function gainsightPlugin(config: GainsightOptions) {
  return {
    name: 'gainsight',
    config,
    methods: {},
    bootstrap: noop,
    initialize: ({ config, instance, payload }: any) => {
      const win = window as any;
      const doc = document as any;

      if (!win) {
        throw new Error('No window object found, ignoring Gainsight');
      }

      // This is set in our root index.html file. It's the only real
      // way I could get this to turn off in development.
      if (win.BERA_IS_LOCAL === true) {
        console.info(
          'Gainsight not loaded. Set window.BERA_IS_LOCAL to false in index.html to enable.'
        );
        return false;
      }

      const { apiKey } = config;

      if (!apiKey) {
        throw new Error('No Gainsight api key provided');
      }

      if (!win.aptrinsic) {
        win.aptrinsic = function () {
          win.aptrinsic.q = win.aptrinsic.q || [];
          win.aptrinsic.q.push(arguments);
        };

        win.aptrinsic.c = {
          iframeModeEnabled: false,
          espProxyDomain: 'https://gainsight.bera.ai',
          contentProxyDomain: 'https://gainsight-sdk.bera.ai',
        };

        win.aptrinsic.p = apiKey;
      }

      if (!isScriptLoaded(doc)) {
        const script = doc.createElement('script');
        script.src = `https://gainsight-sdk.bera.ai/api/aptrinsic.js?a=${apiKey}`;
        script.async = true;
        const first = doc.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
      }
    },
    page: noop,
    track: noop,
    identify: ({ payload }: any) => {
      const win = window as any;

      if (!win.aptrinsic) {
        return false;
      }

      win.aptrinsic('identify', payload.traits, payload.traits.company || {});
    },
    reset: () => {
      const win = window as any;

      if (!win.aptrinsic) {
        return false;
      }

      win.aptrinsic('reset');
    },
    ready: noop,
    loaded: () => {
      const win = window as any;

      if (!win.aptrinsic) {
        return false;
      }

      return win.aptrinsic.init;
    },
  };
}
