const GRAIN_TO_VALUE_MAP = new Map<Bera.DateGrain, number>([
  ['weekly', 0],
  ['quarterly', 1],
  ['monthly', 2],
  ['annually', 3],
]);

const DEFAULT: Bera.DateGrain = 'annually';

interface Args {
  dataset?: Bera.Dataset | Bera.SubscriptionDataset;
  subscription?: Bera.Subscription;
}

export default function getLowestGrain({ dataset, subscription }: Args) {
  const datasetGrain = dataset?.lowestGrain || DEFAULT;
  const subscriptionGrain = subscription?.grain || DEFAULT;
  return Number(GRAIN_TO_VALUE_MAP.get(datasetGrain)) >
    Number(GRAIN_TO_VALUE_MAP.get(subscriptionGrain))
    ? datasetGrain
    : subscriptionGrain;
}
