/**
 * Add an item at a specific place in an array
 *
 * @param arr Input array
 * @param item Item to add into array
 * @param at Where to insert the item (not zero indexed)
 */
function insertArrayItem<T = any>(arr: T[], item: T, at?: number) {
  const insertionIndex = at === undefined ? arr.length : at;
  const newArr = [...arr];
  newArr.splice(insertionIndex, 0, item);

  return newArr;
}

export default insertArrayItem;
