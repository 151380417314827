import getDefaultState from './get-default-state';

export default function unserialize(
  ser: Bera.GlobalSerializedState
): Bera.GlobalState {
  const defs = getDefaultState();

  return {
    ...defs,
    analysisPeriodEnd: new Date(ser.ape),
    analysisPeriodInterval:
      ser.api as Bera.GlobalState['analysisPeriodInterval'],
    analysisPeriodStart: new Date(ser.aps),
    analysisPeriodValue: ser.apv as Bera.GlobalState['analysisPeriodValue'],
    audience: ser.aud,
    competitiveAudiences: ser.cad,
    competitiveSet: ser.cmp,
    country: ser.cnt,
    countryCode: ser.cnc,
    dataset: ser.dts,
    mode: ser.mod as Bera.GlobalState['mode'],
    primary: ser.pri,
    revision: 0,
    studies: ser.std,
    subscription: ser.sub,
    utcAnalysisPeriodEnd: new Date(ser.upe),
    utcAnalysisPeriodStart: new Date(ser.ups),
  };
}
