import formatUtcDate from 'utils/dates/format-utc-date';

import getDefaultDatePeriod from './get-default-date-period';
import getDefaultDateRangeForInterval from './get-default-date-range-for-interval';
import getIntervalByGrain from './get-interval-by-grain';
import getLowestGrain from './get-lowest-grain';
import getMaxEndDateForIntervals from './get-max-end-dates-for-intervals';

export default function getDefaultAnalysisPeriodForSubscription(
  subscription?: Bera.Subscription,
  dataset?: Bera.Dataset | Bera.SubscriptionDataset
) {
  const ready = subscription && dataset;
  const lowestGrain = ready
    ? getLowestGrain({ dataset, subscription })
    : 'annually';
  const lowestInterval = getIntervalByGrain(lowestGrain);
  const newestDates = getMaxEndDateForIntervals(dataset, subscription);
  const end = newestDates.get(lowestInterval) || new Date();
  const range = getDefaultDateRangeForInterval(end, lowestInterval);
  const analysisPeriodStart = range[0];
  const analysisPeriodEnd = range[1];

  return {
    analysisPeriodEnd,
    analysisPeriodStart,
    analysisPeriodInterval: lowestInterval,
    analysisPeriodValue: getDefaultDatePeriod(lowestInterval),
    utcAnalysisPeriodEnd: formatUtcDate(analysisPeriodEnd),
    utcAnalysisPeriodStart: formatUtcDate(analysisPeriodStart),
  };
}
