import {
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';

type StartDateFn = () => Date;

export default function getDefaultDateRangeForInterval(
  end: Date,
  interval: Bera.DateInterval
): [Date, Date] {
  const dataMap = new Map<Bera.DateInterval, StartDateFn>([
    ['weekly', () => startOfWeek(subWeeks(end, 8), { weekStartsOn: 2 })],
    ['monthly', () => startOfMonth(subMonths(end, 8))],
    ['quarterly', () => startOfQuarter(subQuarters(end, 7))],
    ['yearly', () => startOfYear(subYears(end, 4))],
  ]);

  const fn = dataMap.get(interval) || (dataMap.get('yearly') as StartDateFn);
  return [fn(), end];
}
