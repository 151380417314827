import React from 'react';

import { Route, RouteProps } from 'react-router-dom';

import useLaunchpad from 'contexts/launchpad';
import findArrayIntersection from 'utils/array/find-array-intersection';

import Unauthorized from './errors/unauthorized';

export interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
  applicationId: string;
  component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  applicationId,
  component: Component,
  ...rest
}) => {
  const { applications, roles } = useLaunchpad();
  const app = applications.find((a) => a.id === applicationId);
  const allowed = findArrayIntersection(app?.iamRoles || [], roles).length > 0;

  if (!Component || !app) {
    return null;
  }

  return (
    <Route
      {...rest}
      path={app?.url}
      render={() => {
        return allowed ? <Component /> : <Unauthorized app={app?.name} />;
      }}
    />
  );
};

export default ProtectedRoute;
