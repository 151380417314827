import React, { MouseEvent, useState } from 'react';

import { IconButton, Link, Popover, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Gravatar } from '../gravatar';

const WIDTH = 200;

export interface UserSelectorProps {
  user?: Bera.User;
  onSignout?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    minWidth: WIDTH,
    fontSize: theme.typography.pxToRem(16),
  },
  avatar: {
    border: '2px solid rgba(255, 255, 255, 0.875)',
    width: 36,
    height: 36,
  },
}));

const UserSelector: React.FC<UserSelectorProps> = ({ user, onSignout }) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState<any>(null);
  const { t } = useTranslation('ui');
  const open = Boolean(anchor);

  const handleSignout = (e: MouseEvent) => {
    e.preventDefault();
    setAnchor(null);
    if (onSignout) {
      onSignout();
    }
  };

  if (!user) {
    return null;
  }

  return (
    <React.Fragment>
      <IconButton
        data-testid="open-button"
        aria-label="User options dropdown"
        aria-haspopup="true"
        data-cy="user-avatar"
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <Gravatar className={classes.avatar} user={user} />
      </IconButton>
      <Popover
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={() => setAnchor(null)}
      >
        <div className={classes.root}>
          <Link
            href="#"
            data-cy="logout"
            color="primary"
            onClick={handleSignout}
          >
            {t('ui:signout')}
          </Link>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default UserSelector;
