/**
 * Returns audience id in the desired format, irrespective of the format of the source id
 *
 * @param audience Audience id in either `default (1234)` or `full (a:1234)` format
 * @param format Desired return format: `default (1234)`, `full (a:1234)`
 */
export default function formatAudienceId(
  audience: string,
  format: 'full' | 'default' = 'default'
) {
  if (format === 'full') {
    return audience.startsWith('a:') ? audience : `a:${audience}`;
  }

  if (audience.startsWith('a:')) {
    const spl = audience.split(':');
    return spl[1];
  }

  return audience;
}
