import React from 'react';

import { Redirect, RouteComponentProps } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import useLaunchpad from 'contexts/launchpad';

import determineDefaultApp from './determine-default-app';

const AuthCallback: React.FC<RouteComponentProps> = () => {
  const { authenticated, tokens } = useAuth();
  const { allowedApplications, lastUsedApp, loading } = useLaunchpad();

  if (!authenticated || loading) {
    return null;
  }

  const referer = tokens?.referer?.includes('callback') ? '/' : tokens?.referer;
  const fallback = determineDefaultApp(allowedApplications, lastUsedApp);

  return <Redirect to={!referer || referer === '/' ? fallback : referer} />;
};

export default AuthCallback;
