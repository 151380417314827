export type ValidEnvironment = 'test' | 'development' | 'production';
type PEnv = Record<string, any> & { NODE_ENV?: ValidEnvironment };

export interface Environment {
  cdnUrl: string;
  graphqlServerURL: string;
  graphqlSupportURL: string;
  tokenRefreshURL: string;
  iamServerUrl: string;
  iamClientId: string;
  inactivityPeriod: number;
  environment: ValidEnvironment;
  baHost: string;
  detHost: string;
  btbHost: string;
  btbcsHost: string;
  semHost: string;
}

const prefixMap = {
  production: 'REACT_APP_PROD_',
  development: 'REACT_APP_DEV_',
  test: 'REACT_APP_TEST_',
};

export default function getEnvironment(
  env: PEnv = {},
  forceEnv?: ValidEnvironment
): Environment {
  const useEnv: ValidEnvironment = forceEnv || env.NODE_ENV || 'production';
  const prefix = prefixMap[useEnv];

  const resolve = (key: string, defaultValue?: any) => {
    const value = env[`${prefix}${key}`];
    return value === undefined ? defaultValue : value;
  };

  const graphqlSupportURL = resolve(
    'GRAPHQL_SUPPORT_URL',
    'https://graph.bera.ai'
  );

  return {
    cdnUrl: env.REACT_APP_CDN_URL || 'https://cdn.bera.ai',
    graphqlServerURL: resolve(
      'GRAPHQL_SERVER_URL',
      'https://graph.bera.ai/graphql'
    ),
    graphqlSupportURL,
    tokenRefreshURL: `${graphqlSupportURL}/oauth2/token`,
    iamServerUrl: resolve('IAM_SERVER_URL', 'https://iam.bera.ai'),
    iamClientId: resolve('IAM_CLIENT_ID', ''),
    inactivityPeriod: parseInt(resolve('INACTIVTY_PERIOD', '14400000'), 10),
    environment: resolve('ENVIRONMENT', 'production'),
    baHost: resolve('BA_HOST', 'https://brand-accelerator.bera.workers.dev'),
    detHost: resolve('DET_HOST', 'https://data-export.bera.ai'),
    btbHost: resolve(
      'BTB_HOST',
      'https://brand-to-business-tool.bera.ai/ds-b2b/'
    ),
    btbcsHost: resolve(
      'BTBCS_HOST',
      'https://brand-to-business-cs-tool.bera.ai/cross-sectional-b2b/'
    ),
    semHost: resolve(
      'DSSEM_HOST',
      'https://ds-sem-tool.bera.ai/ds-sem-prototype/'
    ),
  };
}
