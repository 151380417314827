import React from 'react';

import { Link, Typography } from '@material-ui/core';

import { useAuth } from '../../contexts/auth';
import { Base } from './base';

export interface UnknownProps {
  app?: string;
}

export const Unknown: React.FC<UnknownProps> = ({
  app = 'this application',
}) => {
  const { logout } = useAuth();

  const handleLogout = (e: any) => {
    e.preventDefault();
    logout();
  };

  const description = (
    <Typography>
      We are currently unable to load {app}. This error has been sent to our
      technical team, and we will investigate the cause as soon as possible.
      Please <Link onClick={handleLogout}>click here</Link>
      to get back to the home page, you may be asked to login again.
    </Typography>
  );

  return <Base description={description} />;
};

export default Unknown;
