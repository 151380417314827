import getMaxEndDateForInterval from './get-max-end-date-for-interval';

export default function getMaxEndDateForIntervals(
  dataset?: Bera.Dataset | Bera.SubscriptionDataset,
  subscription?: Bera.Subscription
) {
  return new Map<Bera.DateInterval, Date | null>([
    ['weekly', getMaxEndDateForInterval('weekly', dataset, subscription)],
    ['monthly', getMaxEndDateForInterval('monthly', dataset, subscription)],
    ['quarterly', getMaxEndDateForInterval('quarterly', dataset, subscription)],
    ['yearly', getMaxEndDateForInterval('yearly', dataset, subscription)],
  ]);
}
