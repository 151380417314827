import React, { CSSProperties } from 'react';

import useEnvironment from 'hooks/use-environment';

export interface ApplicationLogoProps {
  application?: Bera.Application;
  size?: CSSProperties['width'];
}

const ApplicationLogo: React.FC<ApplicationLogoProps> = ({
  application,
  size = 32,
}) => {
  const { cdnUrl } = useEnvironment();
  const logo = application?.logo || 'unassigned';
  const url = `${cdnUrl}/logos/applications/${logo}.svg`;

  const style = {
    width: size,
    height: size,
    backgroundColor: 'transparent',
    borderRadius: 6,
  };

  return <img src={url} style={style} alt="Application logo" />;
};

export default ApplicationLogo;
