import { alpha } from '@material-ui/core';

function makeGradiatedColors(
  h: number,
  s: number,
  ls: number[],
  p?: number,
  bg?: number,
  d?: number
) {
  const output: Bera.ColorSet = {};

  if (p) output.primary = `hsl(${h}, ${s}%, ${p}%)`;
  if (bg) output.background = `hsl(${h}, ${s}%, ${bg}%)`;
  if (d) output.disabled = `hsl(${h}, ${s}%, ${d}%)`;

  return ls.reduce((acc, l, i) => {
    acc[(i + 1) as keyof Bera.ColorSet] = `hsl(${h}, ${s}%, ${l}%)`;
    return acc;
  }, output);
}

const blue = makeGradiatedColors(
  199,
  100,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90, 96, 98],
  50,
  98,
  10
);
const primary = blue[6];

const neutral = makeGradiatedColors(0, 0, [20, 30, 45, 60, 86, 94]);
const black = 'hsl(0, 0%, 0%)';
const white = 'hsl(0, 0%, 100%)';

const grass = makeGradiatedColors(
  149,
  68,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90],
  50,
  98,
  82
);
const strawberry = makeGradiatedColors(
  358,
  100,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90],
  66,
  98,
  90
);
const blueberry = makeGradiatedColors(
  240,
  84,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90],
  58,
  98,
  82
);
const plum = makeGradiatedColors(
  274,
  100,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90],
  58,
  98,
  82
);
const rose = makeGradiatedColors(
  324,
  87,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90],
  58,
  98,
  82
);
const orange = makeGradiatedColors(
  22,
  96,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90],
  58,
  98,
  82
);
const apricot = makeGradiatedColors(
  44,
  100,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90],
  50,
  98,
  82
);

const disabled = makeGradiatedColors(
  0,
  0,
  [10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 20],
  50,
  98,
  10
);

const progress = {
  high: grass[6],
  highStop: 100,
  medium: apricot[8],
  mediumStop: 80,
  low: strawberry[8],
  lowStop: 30,
};

const backgrounds = {
  page: 'hsl(210, 33%, 98%)',
  grass: grass[12],
  strawberry: strawberry[12],
  blueberry: blueberry[12],
  plum: plum[12],
  rose: rose[12],
  orange: orange[12],
  apricot: apricot[12],
};

export type RelationshipStage =
  | 'new'
  | 'dating'
  | 'love'
  | 'love-'
  | 'love+'
  | 'boredom'
  | 'divorce'
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6;

const relationshipStages = {
  new: blue[6],
  dating: plum[7],
  love: rose[6],
  boredom: orange[6],
  divorce: apricot[6],

  isRelationshipStage(stage: any): stage is RelationshipStage {
    if (typeof stage === 'number') {
      return stage >= 0 && stage <= 6;
    }
    return [
      'new',
      'dating',
      'love',
      'love-',
      'love+',
      'boredom',
      'divorce',
    ].includes(stage);
  },

  collectStage: (stage: string | number) => {
    if (relationshipStages.isRelationshipStage(stage)) {
      if (typeof stage === 'number') {
        if (stage >= 0 && stage <= 6) {
          return parseInt(String(stage)) as RelationshipStage;
        }
      }
      return stage;
    }
  },

  getColorForStage: (stage?: RelationshipStage) => {
    switch (stage) {
      case 'new':
      case 0:
      case 1:
        return blue[6];
      case 'dating':
      case 2:
        return plum[7];
      case 'love':
      case 'love-':
      case 'love+':
      case 3:
      case 4:
        return rose[6];
      case 'boredom':
      case 5:
        return orange[6];
      case 'divorce':
      case 6:
        return apricot[6];
      default:
        return strawberry[6];
    }
  },
};

export type Construct =
  | 'purpose'
  | 'emotional'
  | 'functional'
  | 'experiential'
  | 'custom'
  | 1
  | 2
  | 3
  | 4
  | 5;

function makeConstructGradient(set: Bera.ColorSet): [string, string, string] {
  return [set[11]!, alpha(set[11]!, 0.0208), set[9]!];
}

// AKA Constructs in brand positioning
const constructs = {
  purpose: apricot.primary!,
  purposeGradient: makeConstructGradient(apricot),
  emotional: orange.primary!,
  emotionalGradient: makeConstructGradient(orange),
  functional: strawberry.primary!,
  functionalGradient: makeConstructGradient(strawberry),
  experiential: rose.primary!,
  experientialGradient: makeConstructGradient(rose),
  custom: blueberry.primary!,
  customGradient: makeConstructGradient(blueberry),
  disabled: disabled.primary!,
  disabledGradient: [
    disabled[6]!,
    alpha(disabled[6]!, 0.0208),
    disabled[4]!,
  ] as [string, string, string],
};

const engagementSegments = {
  unawares: blue[7]!,
  rejecters: strawberry[9]!,
  lapsed: orange[8]!,
  winbacks: apricot[6]!,
  prospects: apricot[8]!,
  switchers: grass[5]!,
  loyals: grass[3]!,
  nonCustomers: neutral[4]!,
  customers: grass[2]!,
};

export const colors = {
  beraBlue: blue[6],
  blue,
  primary,
  neutral,
  black,
  white,
  grass,
  strawberry,
  blueberry,
  plum,
  rose,
  orange,
  apricot,
  backgrounds,
  relationshipStages,
  constructs,
  transparent: 'transparent',
  progress,
  engagementSegments,
};

export default colors;
