import { makeStyles } from '@material-ui/core';

const FW_NORMAL = 400;
const FW_BOLD = 700;
const FW_SEMIBOLD = 500;
const OP_NORMAL = 1;
const OP_MUTED = 0.6;

export const useFontStyles = makeStyles(
  (theme) => ({
    body3: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(13),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    mutedBody1: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      opacity: OP_MUTED,
    },
    mutedBody2: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      opacity: OP_MUTED,
    },
    mutedBody3: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.body2.lineHeight,
      opacity: OP_MUTED,
    },
    pageTitle: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    contentTitle: {
      fontWeight: FW_BOLD,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    contentLabeledTitle: {
      fontWeight: FW_SEMIBOLD,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    contentTitleLabel: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(11),
      lineHeight: 1.35,
      opacity: OP_MUTED,
      textTransform: 'uppercase',
    },
    secondTitle1: {
      fontWeight: FW_SEMIBOLD,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    sectionTitle2: {
      fontWeight: FW_SEMIBOLD,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    sectionTitle3: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(11),
      lineHeight: 1.35,
      opacity: OP_MUTED,
      textTransform: 'uppercase',
    },
    brandLabel: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    categoryLabel: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.35,
      opacity: OP_MUTED,
    },
    inlineLabel: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_MUTED,
    },
    inlineContent: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    metric: {
      fontWeight: FW_SEMIBOLD,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    metricLabel: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    callout: {
      fontWeight: FW_BOLD,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    headingLabel: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(11),
      lineHeight: 1.35,
      opacity: OP_MUTED,
      textTransform: 'uppercase',
    },
    headingContent: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    headingHint: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(11),
      lineHeight: 1.35,
      opacity: OP_MUTED,
    },
    modalTitle: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    modalSubtitle: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.35,
      opacity: OP_NORMAL,
    },
    listGroup: {
      fontWeight: FW_NORMAL,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.35,
      opacity: OP_MUTED,
      textTransform: 'uppercase',
    },
  }),
  { name: 'B-Typography', meta: 'B-Typography', index: 900 }
);

export default useFontStyles;
