import React from 'react';

import { Theme, alpha, withStyles } from '@material-ui/core';
import MuiAlert, { AlertProps as MuiAlertProps } from '@material-ui/lab/Alert';

import colors from 'data/colors';

function makeStandard(palette: Bera.ColorSet, dark = false) {
  if (dark) {
    return {
      border: `1px solid ${palette[4]}`,
      backgroundColor: alpha(palette[6]!, 0.125),
      color: colors.neutral[5],
      fontWeight: 400,
    };
  }

  return {
    border: `1px solid ${palette[11]}`,
    backgroundColor: palette[12],
    color: colors.neutral[2],
    fontWeight: 400,
  };
}

function makeOutlined(palette: Bera.ColorSet, dark = false) {
  if (dark) {
    return {
      border: `1px solid ${palette[4]}`,
      backgroundColor: colors.transparent,
      color: palette[6],
      fontWeight: 400,
    };
  }

  return {
    border: `1px solid ${palette[7]}`,
    backgroundColor: colors.transparent,
    color: palette[6],
    fontWeight: 400,
  };
}

function makeFilled(palette: Bera.ColorSet, _dark = false) {
  return {
    backgroundColor: palette[8],
    color: palette[2],
    fontWeight: 400,
  };
}

const styles = (theme: Theme) => {
  const dark = theme.palette.type === 'dark';

  return {
    root: {
      fontSize: theme.typography.pxToRem(14),
    },
    standardError: makeStandard(colors.strawberry, dark),
    standardInfo: makeStandard(colors.blue, dark),
    standardSuccess: makeStandard(colors.grass, dark),
    standardWarning: makeStandard(colors.apricot, dark),
    outlinedError: makeOutlined(colors.strawberry, dark),
    outlinedInfo: makeOutlined(colors.blue, dark),
    outlinedSuccess: makeOutlined(colors.grass, dark),
    outlinedWarning: makeOutlined(colors.apricot, dark),
    filledError: makeFilled(colors.strawberry, dark),
    filledInfo: makeFilled(colors.blue, dark),
    filledSuccess: makeFilled(colors.grass, dark),
    filledWarning: makeFilled(colors.apricot, dark),
  };
};

const StyledAlert = withStyles(styles)(MuiAlert);

export interface AlertProps extends MuiAlertProps {
  hide?: boolean;
}

export const Alert: React.FC<AlertProps> = ({ hide = false, ...props }) => {
  return hide ? null : <StyledAlert {...props} />;
};

export default Alert;
