/**
 * Find all elements in both arrays
 *
 * @param a Left array
 * @param b Right array
 */
function findArrayIntersection<T = any>(a: T[], b: T[]) {
  return a.filter((x) => b.includes(x));
}

export default findArrayIntersection;
