import React, { useMemo } from 'react';

import {
  Avatar as MUIAvatar,
  AvatarProps as MUIAvatarProps,
} from '@material-ui/core';
import md5 from 'md5';

import firstChars from 'utils/text/first-chars';

export type User = {
  email: string;
  fullName?: string;
};

export interface GravatarProps extends MUIAvatarProps {
  user: User;
}

export const Gravatar: React.FC<GravatarProps> = ({ user, ...props }) => {
  const uProps = useMemo(() => {
    if (user) {
      const src = `https://www.gravatar.com/avatar/${md5(
        user.email.trim().toLowerCase()
      )}?d=404&s=40`;

      return {
        children: firstChars(user.fullName || user.email),
        src,
        alt: user.fullName || user.email,
      };
    }

    return {};
  }, [user]);

  return <MUIAvatar {...uProps} {...props} />;
};
