import React from 'react';

import { Divider, Paper, Typography, makeStyles } from '@material-ui/core';

export interface BaseProps {
  title?: string;
  description?: React.ReactNode | string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    width: '100%',
  },
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  display: {
    maxWidth: '40%',
    padding: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export const Base: React.FC<BaseProps> = ({
  title = 'Unknown Error',
  description,
}) => {
  const classes = useStyles();

  const output =
    typeof description === 'string' ? (
      <Typography>{description}</Typography>
    ) : (
      description
    );

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.root}>
        <Paper elevation={0} className={classes.display}>
          <Typography variant="h3">{title}</Typography>
          <Divider variant="fullWidth" className={classes.divider} />
          {output}
        </Paper>
      </div>
    </Paper>
  );
};

export default Base;
