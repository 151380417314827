import React, { useState } from 'react';

import { IconButton, Link, Popover, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import colors from 'data/colors';
import useLastAppMutation from 'data/use-last-app-mutation';
import MenuGridO from 'icons/css.gg/menu-grid-o';

import ApplicationLogo from '../application/logo';

const WIDTH = 200;

export type ApplicationSelectedCallback = (
  application: Bera.Application
) => void;

export interface ApplicationSelectorProps {
  applications: Bera.Application[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 3, 1, 2),
    minWidth: WIDTH,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.transparent,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  label: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: 'nowrap',
  },
  iconButton: {
    fontSize: theme.typography.pxToRem(24),
    color: colors.white,
  },
}));

const ApplicationSelector: React.FC<ApplicationSelectorProps> = ({
  applications = [],
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [anchor, setAnchor] = useState<any>(null);
  const { t } = useTranslation(['products']);
  const [updateLastUsedApp] = useLastAppMutation();
  const open = Boolean(anchor);

  const handleSelect = (app: Bera.Application) => {
    if (!location.pathname.startsWith(app.url)) {
      updateLastUsedApp({
        variables: { value: app.url },
      });
    }

    setAnchor(null);
  };

  return (
    <React.Fragment>
      <IconButton
        data-testid="open-button"
        className={classes.iconButton}
        aria-label="Application selector"
        aria-haspopup="true"
        data-cy="app-selector"
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <MenuGridO />
      </IconButton>
      <Popover
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={() => setAnchor(false)}
      >
        <div className={classes.root}>
          {applications.map((app) => (
            <Link
              key={`as-link-${app.id}`}
              component={NavLink}
              activeStyle={{ fontWeight: 700, filter: 'none' }}
              to={app.url}
              onClick={() => handleSelect(app)}
              className={classes.item}
            >
              <div className={classes.logo}>
                <ApplicationLogo application={app} size={24} />
              </div>
              <div className={classes.label}>
                {t(`products:${app.id}.name`)}
              </div>
            </Link>
          ))}
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default ApplicationSelector;
