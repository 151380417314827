export type StateTrigger = () => void;

export default class Updater {
  public notifications: Bera.WholeNotification[] = [];

  constructor(private stateTrigger: StateTrigger) {}

  clear = (channel?: Bera.NotificationChannel) => {
    if (channel) {
      this.notifications = this.notifications.filter(
        (n) => n.channel !== channel
      );
    } else {
      this.notifications = [];
    }

    this.stateTrigger();
  };

  notify = (notification: Bera.Notification) => {
    const existingMessage = this.notifications.find(
      (n) => n.message === notification.message
    );

    if (existingMessage) {
      return existingMessage;
    }

    const wholeNotification: Bera.WholeNotification = {
      channel: 'alert',
      expires: Date.now() + 10000,
      ...notification,
      id: String(Date.now()),
    };

    this.notifications = [...this.notifications, wholeNotification];
    this.removeExpired();
    this.stateTrigger();

    return wholeNotification;
  };

  notificationsByChannel = <C extends Bera.NotificationChannel>(channel: C) => {
    this.removeExpired();
    return this.notifications.filter(
      (n) => n.channel === channel
    ) as Bera.WholeNotification<C>[];
  };

  remove = (id: string) => {
    this.notifications = this.notifications.filter((n) => n.id !== id);
    this.stateTrigger();
  };

  removeExpired = () => {
    const anyExpired = this.notifications.some(
      (n) => n.expires && n.expires < Date.now()
    );

    if (anyExpired) {
      this.notifications = this.notifications.filter(
        (n) => !n.expires || n.expires > Date.now()
      );
      this.stateTrigger();
    }
  };
}
