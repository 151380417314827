import { createContext } from 'react';

import { LoginFn, RefreshTokenFn, Tokens, User } from './interfaces';

/**
 * Auth context parameters
 */
export interface AuthContextProps {
  login: LoginFn;
  logout: () => void;
  updateUser: (user: User) => void;
  refreshToken: RefreshTokenFn;
  authenticated: boolean;
  expiringSoon: boolean;
  location?: Location;
  tokens: Tokens | null;
  user: User | null;
}

/**
 * Default auth context
 */
export const AuthContext = createContext<AuthContextProps>({
  login: () => {},
  logout: () => {},
  updateUser: () => {},
  refreshToken: () => {},
  authenticated: false,
  expiringSoon: false,
  location: window?.location,
  tokens: null,
  user: null,
});

AuthContext.displayName = 'AuthContext';
