import React from 'react';

import { TypographyVariant } from '@material-ui/core';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import useFontStyles from './use-font-styles';

const muiVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
];

type BeraVariant =
  | 'pageTitle'
  | 'contentTitle'
  | 'contentLabeledTitle'
  | 'contentTitleLabel'
  | 'secondTitle1'
  | 'sectionTitle2'
  | 'sectionTitle3'
  | 'brandLabel'
  | 'categoryLabel'
  | 'inlineLabel'
  | 'inlineContent'
  | 'metric'
  | 'metricLabel'
  | 'callout'
  | 'headingLabel'
  | 'headingContent'
  | 'headingHint'
  | 'mutedBody1'
  | 'mutedBody2'
  | 'mutedBody3'
  | 'body3'
  | 'modalTitle'
  | 'modalSubtitle'
  | 'listGroup';

export type BeraTypographyVariant = TypographyVariant | BeraVariant;

export interface BeraTypographyProps<
  D extends React.ElementType = 'span' | 'p' | 'div'
> extends Omit<TypographyProps, 'variant'> {
  component?: D;
  variant: BeraTypographyVariant;
}

export const BeraTypography: React.FC<BeraTypographyProps> = ({
  component = 'span',
  variant,
  ...props
}) => {
  const classes = useFontStyles();

  if (!variant || muiVariants.includes(variant)) {
    return (
      <Typography
        component={component}
        variant={variant as TypographyVariant}
        {...props}
      />
    );
  }

  return (
    <Typography
      className={classes[variant as BeraVariant]}
      component={component}
      {...props}
    />
  );
};

export default BeraTypography;
