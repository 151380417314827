import segmentPlugin from '@analytics/segment';
import Analytics from 'analytics';

import gainsightPlugin from './gainsight';

const analytics = Analytics({
  app: 'launchpad',
  plugins: [
    segmentPlugin({
      writeKey: '5EYkXnSme6ohsLetIZWTOZLBrqFthvF6',
    }),
    gainsightPlugin({
      apiKey: 'AP-7R40Y2SYBVMI-2',
    }),
  ],
});

export default analytics;
