import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';

import NorthStar from 'components/loading/north-star';
import BeraTypography from 'components/typography';

import LaunchpadContext, { LaunchpadContextState } from './context';
import useLaunchpadData from './data';

const DataLoading: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <NorthStar />
      <BeraTypography variant="secondTitle1" style={{ marginTop: '2rem' }}>
        Personalizing your experience
      </BeraTypography>
    </Box>
  );
};

const LaunchpadProvider: React.FC = ({ children }) => {
  const d = useLaunchpadData();
  const loaded = d.called && d.subscriptions.length > 0; // More conditions to come.

  const value: LaunchpadContextState = useMemo(() => {
    return {
      allowedApplications: d.allowedApplications,
      applications: d.applications || [],
      lastUsedApp: d.lastUsedApp,
      loaded,
      loading: d.loading,
      roles: d.roles,
      subscriptions: d.subscriptions,
      userInfo: d.userInfo,
      appSubscriptions: (...roles) => {
        return d.subscriptions.filter((s) => {
          return s.productRoles.some((r) => roles.includes(r));
        });
      },
      datasets: (subId) => {
        const sub = d.subscriptions.find((s) => subId === s.id);

        if (!sub) {
          throw new Error(`Subscription ${subId} not found`);
        }

        return sub.datasets;
      },
    };
  }, [d, loaded]);

  return (
    <LaunchpadContext.Provider value={value}>
      {loaded ? children : <DataLoading />}
    </LaunchpadContext.Provider>
  );
};

export default LaunchpadProvider;
