import { addDays, endOfDay } from 'date-fns';

import getGrainByInterval from './get-grain-by-interval';

const TODAY: Date = endOfDay(new Date());

export default function getMaxEndDateForInterval(
  interval: Bera.DateInterval,
  dataset?: Bera.Dataset | Bera.SubscriptionDataset,
  subscription?: Bera.Subscription
) {
  if (!dataset && !subscription) {
    return null;
  }
  const grain = getGrainByInterval(interval);
  const applicableGrainDate = dataset?.grainDates?.find(
    (grainDate) => grainDate.key === grain
  );

  const datasetEndDate = applicableGrainDate
    ? endOfDay(new Date(applicableGrainDate.end))
    : null;

  if (!datasetEndDate) {
    return null;
  }

  const hasSubToDate = subscription && subscription.to;
  const subscriptionEndDate = hasSubToDate
    ? endOfDay(new Date(String(subscription.to)))
    : null;

  let lowestDate: Date = datasetEndDate;
  if (subscriptionEndDate) {
    lowestDate =
      subscriptionEndDate < datasetEndDate
        ? subscriptionEndDate
        : datasetEndDate;
  }

  if (lowestDate < TODAY) {
    return addDays(lowestDate, 1);
  }
  return TODAY;
}
