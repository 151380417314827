import React from 'react';

import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';

import useEnvironment from 'hooks/use-environment';

import ApplicationSelector from './application-selector';
import HeaderBranding from './branding';
import UserSelector from './user-selector';

export interface HeaderProps {
  activeApplication?: Bera.Application;
  applications: Bera.Application[];
  user?: Bera.User;
  onSignout?: () => void;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 56,
  },
  toolbar: {
    backgroundColor: 'black',
    color: 'white',
    minHeight: 56,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toolbarNonProd: {
    backgroundColor: 'hsl(25, 75%, 60%)',
  },
  spacer: {
    flexGrow: 1,
  },
}));

const Header: React.FC<HeaderProps> = ({
  activeApplication,
  applications,
  user,
  onSignout,
}) => {
  const env = useEnvironment();
  const classes = useStyles();

  const toolbarClasses = clsx({
    [classes.toolbar]: true,
    [classes.toolbarNonProd]: env.environment !== 'production',
  });

  return (
    <React.Suspense fallback={null}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={toolbarClasses}>
          <HeaderBranding
            application={activeApplication}
            devMode={env.environment !== 'production'}
          />
          <Box flexGrow="1" />
          <Box display="flex" alignItems="center">
            {applications.length > 0 && (
              <ApplicationSelector applications={applications} />
            )}
            <UserSelector user={user} onSignout={onSignout} />
          </Box>
        </Toolbar>
      </AppBar>
    </React.Suspense>
  );
};

export default Header;
