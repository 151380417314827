import React, { useMemo, useRef, useState } from 'react';

import { useInterval } from 'react-use';

import NotifierContext, { NotifierState } from './context';
import Updater from './updater';

export interface NotifierProviderProps {}

const NotifierProvider: React.FC<NotifierProviderProps> = ({ children }) => {
  const [revision, setRevision] = useState(0);
  const ref = useRef(new Updater(() => setRevision(Date.now())));

  useInterval(() => {
    ref.current.removeExpired();
  }, 2000);

  const value: NotifierState = useMemo(() => {
    const cur = ref.current;

    return {
      notifications: cur.notifications,
      clear: cur.clear,
      notify: cur.notify,
      notificationsByChannel: cur.notificationsByChannel,
      remove: cur.remove,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revision]);

  return (
    <NotifierContext.Provider value={value}>
      {children}
    </NotifierContext.Provider>
  );
};

export default NotifierProvider;
