export default function serialize(state: Bera.GlobalState) {
  return {
    ape: state.analysisPeriodEnd.toJSON(),
    api: state.analysisPeriodInterval!,
    aps: state.analysisPeriodStart.toJSON(),
    apv: state.analysisPeriodValue!,
    aud: state.audience!,
    cad: state.competitiveAudiences,
    cmp: state.competitiveSet,
    cnt: state.country!,
    cnc: state.countryCode!,
    dts: state.dataset!,
    mod: state.mode,
    pri: state.primary!,
    std: state.studies,
    sub: state.subscription!,
    upe: state.utcAnalysisPeriodEnd.toJSON(),
    ups: state.utcAnalysisPeriodStart.toJSON(),
  } as Bera.GlobalSerializedState;
}
