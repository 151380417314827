import 'reflect-metadata';

import React from 'react';

import i18n from 'i18next';
import Detector from 'i18next-browser-languagedetector';
import Fetch from 'i18next-fetch-backend';
import { Pages } from 'pages';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';

import { ContextProviders } from 'contexts';
import GlobalState from 'contexts/global-state/provider';

import reportWebVitals from './reportWebVitals';
import NotifierProvider from 'contexts/notifier/provider';

import './tailwind.css';

i18n
  .use(Detector)
  .use(initReactI18next)
  .use(Fetch)
  .init({
    debug: process.env.ENVIRONMENT === 'development',
    appendNamespaceToCIMode: true,
    appendNamespaceToMissingKey: true,
    backend: {
      loadPath: `${process.env.REACT_APP_CDN_URL}/i18n/{{lng}}/{{ns}}.json`,
    },
    defaultNS: 'ui',
    detection: {
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang',
      lookupSessionStorage: 'lang',
      caches: ['localStorage'],
    },
    fallbackLng: 'en',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    lowerCaseLng: true,
    lng: process.env.NODE_ENV === 'test' ? 'cimode' : undefined,
    nonExplicitSupportedLngs: true,
    partialBundledLanguages: true,
  });

const App = () => (
  <React.StrictMode>
    <NotifierProvider>
      <ContextProviders>
        <GlobalState persist persistenceKey="global-data">
          <Pages />
        </GlobalState>
      </ContextProviders>
    </NotifierProvider>
  </React.StrictMode>
);

if (process.env.NODE_ENV === 'development') {
  import('react-axe').then((axe) => {
    axe.default(React, ReactDOM, 1000);
    ReactDOM.render(<App />, document.getElementById('root'));
  });
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

reportWebVitals();
