import React from 'react';

import { makeStyles } from '@material-ui/core';

import Alert from 'components/alert';
import useNotifier from 'contexts/notifier';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 56 + 16,
    right: 16,
    zIndex: 9999,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginBottom: 16,
    maxWidth: 400,
  },
});

export interface ToastContainerProps {}

const ToastContainer: React.FC<ToastContainerProps> = () => {
  const classes = useStyles();
  const { notificationsByChannel, remove } = useNotifier();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {notificationsByChannel('toast').map((notification) => (
          <div key={notification.id} className={classes.item}>
            <Alert
              variant="filled"
              severity={notification.severity}
              onClose={() => {
                remove(notification.id);
              }}
            >
              {notification.message}
            </Alert>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToastContainer;
