import { gql, useMutation } from '@apollo/client';

const SET_LAST_APP = gql`
  mutation SetLastApp($value: Any!) {
    userPreference(
      category: "bang"
      key: "last-app"
      value: $value
      type: "string"
    ) {
      key
      value
      updatedAt
    }
  }
`;

interface Variables {
  value: string;
}

interface Response {
  lastUsedApp: {
    value: string;
  } | null;
}

export default function useLastAppMutation() {
  return useMutation<Response, Variables>(SET_LAST_APP);
}
