import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from 'contexts/auth';
import useEnvironment from 'hooks/use-environment';

import ZendeskContext from './context';

export interface ZendeskProviderProps {
  disabled?: boolean;
  apiKey: string;
}

const win = window as any;

const ZendeskProvider: React.FC<ZendeskProviderProps> = ({
  children,
  disabled = false,
  apiKey,
}) => {
  const [initialized, setInitialized] = useState(false);
  const { tokens } = useAuth();
  const env = useEnvironment();

  useEffect(() => {
    if (disabled) {
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
    script.addEventListener('load', () => {
      setInitialized(true);
    });

    win.zESettings = {
      webWidget: {
        authenticate: {
          jwtFn: function (callback: Function) {
            const token = tokens?.accessToken || '';
            const baseUrl = env.graphqlSupportURL;

            fetch(`${baseUrl}/zendesk/token`, {
              method: 'POST',
              body: '',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${token}`,
              },
            })
              .then((x) => {
                x.text().then((t) => {
                  callback(t);
                });
              })
              .catch((e) => {
                console.warn('Unable to authenticate with Zendesk', e);
              });
          },
        },
        color: {
          theme: 'F137A7',
          launcher: 'F137A7',
          launcherText: 'FFFFFF',
          button: 'F137A7',
          header: 'F137A7',
        },
      },
    };

    document.body.appendChild(script);

    return () => {
      script.remove();
      win.zE = undefined;
      win.zeSettings = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => {
    const execute = (command: string, ...args: any[]) => {
      if (!initialized || !win.zE) {
        console.warn('Zendesk not initialized');
      }

      if (win.zE) {
        win.zE(command, ...args);
      }
    };

    return {
      initialized,
      clear: () => execute('webWidget', 'clear'),
      close: () => execute('webWidget', 'close'),
      execute: execute,
      logout: () => execute('webWidget', 'logout'),
      open: () => execute('webWidget', 'open'),
      reset: () => execute('webWidget', 'reset'),
      search: (search: string) => {
        execute('webWidget', 'reset');
        execute('webWidget', 'helpCenter:setSuggestions', { search });
      },
      searchLabel: (labels: string[]) => {
        execute('webWidget', 'reset');
        execute('webWidget', 'helpCenter:setSuggestions', { labels });
      },
      toggle: () => execute('webWidget', 'toggle'),
    };
  }, [initialized]);

  return (
    <ZendeskContext.Provider value={value}>{children}</ZendeskContext.Provider>
  );
};

export default ZendeskProvider;
