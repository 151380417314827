import insertArrayItem from 'utils/array/insert-array-item';
import removeArrayItem from 'utils/array/remove-array-item';
import formatAudienceId from 'utils/audience/format-audience-id';
import formatUtcDate from 'utils/dates/format-utc-date';
import getDefaultAnalysisPeriodForSubscription from 'utils/global-data/analysis-period/get-default-analysis-period-for-subscription';
import getDefaultState from 'utils/global-data/get-default-state';
import getDefaultsFromSubscription from 'utils/global-data/get-defaults-from-subscription';
import isSubscriptionDataset from 'utils/type-check/is-subscription-dataset';

export type StateTrigger = (data: Bera.GlobalState, queue?: boolean) => void;

export default class Updater {
  public revision: number = 0;
  public state: Bera.GlobalState;
  private _queue: Partial<Bera.GlobalState>;
  private stateTrigger: StateTrigger;

  get queue() {
    return { ...this.state, ...this._queue };
  }

  constructor(state: Bera.GlobalState, stateTrigger: StateTrigger) {
    this.state = state;
    this._queue = {};
    this.stateTrigger = stateTrigger;
  }

  commit = (callback?: Function) => {
    this.update(this.queue, false);
    this._queue = {};

    if (callback) {
      callback();
    }
  };

  dequeue = () => {
    this._queue = {};
    this.stateTrigger(this.state, false);
  };

  /**
   * Dequeue a specific set of keys
   */
  dequeueByKey = (keys: Array<keyof Bera.GlobalState>) => {
    const update = keys.reduce((_update, key) => {
      _update[key] = this.state[key] as any;
      return _update;
    }, {} as Partial<Bera.GlobalState>);
    this.enqueue(update);
  };

  enqueue = (state: Partial<Bera.GlobalState>) => {
    this.update(state, true);
  };

  refresh = () => {
    this.update({}, false);
  };

  reset = () => {
    this.update(getDefaultState(), false);
  };

  update = (state: Partial<Bera.GlobalState>, queue = false) => {
    if (queue) {
      this._queue = { ...this._queue, ...state };
    } else {
      this.state = { ...this.state, ...state };
    }

    this.stateTrigger(
      queue ? { ...this._queue, ...this.state } : this.state,
      queue
    );
  };

  isReady = (queue = false, skip: (keyof Bera.GlobalState)[] = []) => {
    const state = queue ? { ...this.state, ...this.queue } : this.state;

    const common: (keyof Bera.GlobalState)[] = [
      'analysisPeriodStart',
      'analysisPeriodEnd',
      'analysisPeriodInterval',
      'subscription',
      'country',
      'primary',
      'audience',
    ];

    const filtered = common.filter((key) => !skip.includes(key));

    if (
      filtered.some((key) => state[key] === undefined || state[key] === null)
    ) {
      return false;
    }

    if (!skip.includes('studies') && state.studies.length === 0) {
      return false;
    }

    return true;
  };

  getAllIds = (prefix?: string, queue = false) => {
    const use = queue ? this._queue : this.state;
    const ids = [use.primary, ...(use.competitiveSet || [])];

    const them = ids.filter(Boolean) as string[];

    return prefix ? them.filter((id) => id.startsWith(prefix)) : them;
  };

  /**
   * Retrieve set of Audience ids including both the primary Audience and Competitive Audiences in the desired format
   */
  getAllAudienceIds = ({
    queue = false,
    format = 'default',
  }: {
    queue?: boolean;
    format?: 'full' | 'default';
  }) => {
    const use = queue ? this._queue : this.state;
    const ids = [use.audience, ...(use.competitiveAudiences || [])];

    const returns = ids.filter(Boolean) as string[];
    return returns.map((id) => formatAudienceId(id, format));
  };

  /**
   * Retrieve the primary Audience in the desired format
   */
  getPrimaryAudience = ({
    queue = false,
    format = 'default',
  }: {
    queue?: boolean;
    format?: 'full' | 'default';
  }) => {
    const use = queue ? this._queue : this.state;
    const audience = use.audience;
    if (!audience) {
      return '';
    }

    return formatAudienceId(audience, format);
  };

  /**
   * @todo Remove studies hack once studies are removed from apps
   */
  replaceDataset = ({
    dataset,
    subscription,
    queue = false,
  }: {
    dataset: Bera.Dataset | Bera.SubscriptionDataset;
    subscription?: Bera.Subscription;
    queue?: boolean;
  }) => {
    const {
      analysisPeriodEnd,
      analysisPeriodStart,
      analysisPeriodInterval,
      analysisPeriodValue,
      utcAnalysisPeriodEnd,
      utcAnalysisPeriodStart,
    } = getDefaultAnalysisPeriodForSubscription(subscription, dataset);

    const studies = isSubscriptionDataset(dataset) ? dataset.studies : [];

    const state = {
      dataset: dataset.id,
      country: dataset.countryId,
      countryCode: dataset.countryCode,
      primary: undefined,
      competitiveSet: [],
      studies,
      analysisPeriodEnd,
      analysisPeriodStart,
      analysisPeriodInterval,
      analysisPeriodValue,
      utcAnalysisPeriodEnd,
      utcAnalysisPeriodStart,
    };

    this.update(state, queue);
  };

  replaceSubscription = (subscription: Bera.Subscription, queue = false) => {
    const defaults = getDefaultsFromSubscription(subscription, true);
    this.update(defaults, queue);
  };

  setAnalysisPeriod = (args: Bera.AnalysisPeriodArgs, queue = false) => {
    const update = Updater.calculateAnalysisPeriod(args);
    this.update(update, queue);
  };

  /**
   * Sets the primary Audience in default format (1234)
   */
  setAudience = (audience?: string, queue = false) => {
    this.update({ audience: formatAudienceId(audience!, 'default') }, queue);
  };

  /**
   * Sets the Competitive Audiences in default format (1234)
   */
  setCompetitiveAudiences = (competitiveAudiences: string[], queue = false) => {
    this.update(
      {
        competitiveAudiences: competitiveAudiences.map((id) =>
          formatAudienceId(id, 'default')
        ),
      },
      queue
    );
  };

  setCompetitiveSet = (competitiveSet: string[], queue = false) => {
    const newSet = [...competitiveSet].slice(0, 9);

    this.update({ competitiveSet: newSet }, queue);
  };

  setCountry = (
    country: string,
    countryCode: Bera.Country.Code,
    queue = false
  ) => {
    this.update({ country, countryCode }, queue);
  };

  setDataset = (dataset: string, queue = false) => {
    this.update({ dataset }, queue);
  };

  setMode = (mode: Bera.GlobalState['mode'], queue = false) => {
    this.update({ mode }, queue);
  };

  setPrimary = (id?: string, queue = false) => {
    const comps = new Set(
      queue ? this._queue.competitiveSet : this.state.competitiveSet
    );

    if (id && comps.has(id)) {
      comps.delete(id);
    }

    this.update({ primary: id, competitiveSet: Array.from(comps) }, queue);
  };

  setSubscription = (subscription: string, queue = false) => {
    const defaults = getDefaultState();
    this.update({ ...defaults, subscription }, queue);
  };

  setStudies = (studies: string[], queue = false) => {
    this.update({ studies }, queue);
  };

  swapPrimary = (primary: string, queue = false) => {
    const pri = queue ? this.queue.primary : this.state.primary;
    let competitiveSet =
      queue && this.queue.competitiveSet
        ? [...this.queue.competitiveSet]
        : [...this.state.competitiveSet];

    if (pri) {
      competitiveSet = [pri, ...competitiveSet];
    }

    competitiveSet = competitiveSet.filter((c) => c !== primary);

    this.update({ primary, competitiveSet }, queue);
  };

  swapPrimaryAudience = (primary: string, queue = false) => {
    const audience = formatAudienceId(primary, 'default');

    const pri = queue ? this.queue.audience : this.state.audience;
    let competitiveAudiences =
      queue && this.queue.competitiveAudiences
        ? [...this.queue.competitiveAudiences]
        : [...this.state.competitiveAudiences];

    if (pri) {
      competitiveAudiences = [pri, ...competitiveAudiences];
    }

    competitiveAudiences = competitiveAudiences.filter((c) => c !== audience);
    this.update({ audience, competitiveAudiences }, queue);
  };

  toggleCompetitor = (competitor: string, position?: number, queue = false) => {
    const comps =
      queue && this.queue.competitiveSet
        ? [...this.queue.competitiveSet]
        : [...this.state.competitiveSet];

    const competitiveSet = comps.includes(competitor)
      ? removeArrayItem(comps, competitor)
      : insertArrayItem(comps, competitor, position);

    // Only update if less than 10 brands (primary included)
    if (competitiveSet.length <= 9) {
      this.update({ competitiveSet }, queue);
    }
  };

  toggleAudienceCompetitor = (
    _competitor: string,
    position?: number,
    queue = false
  ) => {
    const competitor = formatAudienceId(_competitor, 'default');

    const comps =
      queue && this.queue.competitiveAudiences
        ? [...this.queue.competitiveAudiences]
        : [...this.state.competitiveAudiences];

    const competitiveAudiences = comps.includes(competitor)
      ? removeArrayItem(comps, competitor)
      : insertArrayItem(comps, competitor, position);

    // Only update if less than 5 competitive audiences
    if (competitiveAudiences.length <= 5) {
      this.setCompetitiveAudiences(competitiveAudiences, queue);
    }
  };

  static calculateAnalysisPeriod(args: Bera.AnalysisPeriodArgs) {
    const update = {} as Partial<Bera.GlobalState>;

    if (args.interval) {
      update.analysisPeriodInterval = args.interval;
    }

    if (args.start) {
      update.analysisPeriodStart = args.start;
      update.utcAnalysisPeriodStart = formatUtcDate(args.start);
    }

    if (args.end) {
      update.analysisPeriodEnd = args.end;
      update.utcAnalysisPeriodEnd = formatUtcDate(args.end);
    }

    if (args.period) {
      update.analysisPeriodValue = args.period;
    }

    return update;
  }
}
