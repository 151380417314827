import { withStyles } from '@material-ui/core';

const defaultFontFamily = [
  'IBM Plex Sans',
  'Roboto',
  'Helvetica Neue',
  '-apple-system',
  'sans-serif',
];
const defaultFontFamilyText = defaultFontFamily.join(', ');

const styles = () => ({
  '@global': {
    'html, body, #root': {
      height: '100%',
      fontFamily: defaultFontFamilyText,
    },
    body: {
      margin: 0,
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    code: {
      fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;`,
    },
    '.react-grid-layout': {
      position: 'relative',
      transition: 'height 200ms ease',
    },
    '.react-grid-item': {
      transition: 'all 200ms ease',
      transitionProperty: 'left, top',
    },
    '.react-grid-item img': {
      pointerEvents: 'none',
      userSelect: 'none',
    },
    '.react-grid-item.cssTransforms': {
      transitionProperty: 'transform',
    },
    '.react-grid-item.resizing': {
      zIndex: 1,
      willChange: 'width, height',
    },
    '.react-grid-item.react-draggable-dragging': {
      transition: 'none',
      zIndex: 3,
      willChange: 'transform',
    },
    '.react-grid-item.dropping': {
      visibility: 'hidden',
    },
    '.react-grid-item.react-grid-placeholder': {
      background: 'gray',
      opacity: 0.2,
      transitionDuration: '100ms',
      zIndex: 2,
      userSelect: 'none',
    },
    '.react-grid-item > .react-resizable-handle': {
      position: 'absolute',
      width: 20,
      height: 20,
    },
    '.react-grid-item > .react-resizable-handle::after': {
      content: '',
      position: 'absolute',
      right: 3,
      bottom: 3,
      width: 5,
      height: 5,
      borderRight: '2px solid rgba(0, 0, 0, 0.2)',
      borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
    },
    '.react-resizable-hide > .react-resizable-handle': {
      display: 'none',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-sw': {
      bottom: 0,
      left: 0,
      cursor: 'sw-resize',
      transform: 'rotate(90deg)',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-se': {
      bottom: 0,
      right: 0,
      cursor: 'se-resize',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-nw': {
      top: 0,
      left: 0,
      cursor: 'nw-resize',
      transform: 'rotate(180deg)',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-ne': {
      top: 0,
      right: 0,
      cursor: 'ne-resize',
      transform: 'rotate(270deg)',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-w, .react-grid-item > .react-resizable-handle.react-resizable-handle-e':
      {
        top: '50%',
        marginTop: -10,
        cursor: 'ew-resize',
      },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-w': {
      left: 0,
      transform: 'rotate(135deg)',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-e': {
      right: 0,
      transform: 'rotate(315deg)',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-n, .react-grid-item > .react-resizable-handle.react-resizable-handle-s':
      {
        left: '50%',
        marginLeft: -10,
        cursor: 'ns-resize',
      },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-n': {
      top: 0,
      transform: 'rotate(225deg)',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-s': {
      bottom: 0,
      transform: 'rotate(45deg)',
    },
    '.react-resizable': {
      position: 'relative',
    },
    '.react-resizable-handle': {
      position: 'absolute',
      width: 20,
      height: 20,
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      boxSizing: 'border-box',
      backgroundImage:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      backgroundPosition: 'bottom right',
      padding: '0 3px 3px 0',
      opacity: '0.5',
    },
    '.react-resizable-handle-sw': {
      bottom: 0,
      left: 0,
      cursor: 'sw-resize',
      transform: 'rotate(90deg)',
    },
    '.react-resizable-handle-se': {
      bottom: 0,
      right: 0,
      cursor: 'se-resize',
    },
    '.react-resizable-handle-nw': {
      top: 0,
      left: 0,
      cursor: 'nw-resize',
      transform: 'rotate(180deg)',
    },
    '.react-resizable-handle-ne': {
      top: 0,
      right: 0,
      cursor: 'ne-resize',
      transform: 'rotate(270deg)',
    },
    '.react-resizable-handle-w, .react-resizable-handle-e': {
      top: '50%',
      marginTop: -10,
      cursor: 'ew-resize',
    },
    '.react-resizable-handle-w': {
      left: 0,
      transform: 'rotate(135deg)',
    },
    '.react-resizable-handle-e': {
      right: 0,
      transform: 'rotate(315deg)',
    },
    '.react-resizable-handle-n, .react-resizable-handle-s': {
      left: '50%',
      marginLeft: -10,
      cursor: 'ns-resize',
    },
    '.react-resizable-handle-n': {
      top: 0,
      transform: 'rotate(225deg)',
    },
    '.react-resizable-handle-s': {
      bottom: 0,
      transform: 'rotate(45deg)',
    },
    '@keyframes react-loading-skeleton': {
      '100%': {
        transform: 'translateX(100%)',
      },
    },
    '.react-loading-skeleton': {
      '--base-color': '#ebebeb',
      '--highlight-color': '#f5f5f5',
      '--animation-duration': '1.5s',
      '--animation-direction': 'normal',
      '--pseudo-element-display': 'block',

      backgroundColor: 'var(--base-color)',
      width: '100%',
      borderRadius: '0.25rem',
      display: 'inline-flex',
      lineHeight: 1,
      position: 'relative',
      overflow: 'hidden',
      zIndex: 1,
    },
    '.react-loading-skeleton::after': {
      content: ' ',
      display: 'var(--pseudo-element-display)',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundImage:
        'linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color))',
      transform: 'translateX(-100%)',
      animationName: 'react-loading-skeleton',
      animationDirection: 'var(--animation-direction)',
      animationDuration: 'var(--animation-duration)',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
    },
    '@media (prefers-color-scheme: dark)': {
      '.react-loading-skeleton': {
        '--base-color': 'rgba(255, 255, 255, 0.25)',
        '--highlight-color': 'rgba(255, 255, 255, 0.35)',
      },
    },
  },
});

function globalStyles() {
  return null;
}

export const GlobalStyles = withStyles(styles, { withTheme: true })(
  globalStyles
);
