import * as React from 'react';

interface State {
  hasError: boolean;
}

interface Props {
  fallback?: React.Component | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    fallback: <h1>something went wrong</h1>,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    if (process.env.NODE_ENV !== 'production') {
      const encodedUrl = `http://stackoverflow.com/search?q=${encodeURIComponent(
        error.message
      )}`;
      // tslint:disable-next-line:no-console
      console.error(error, info);
      console.log(encodedUrl);
    }
  }

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  // componentDidCatch(_error: any, _errorInfo: any) {
  //   // You can also log the error to an error reporting service
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
