import { from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import crypto from 'crypto';
import decode from 'jwt-decode';

import { Environment } from 'utils/environment';

import { LoginFn, RefreshTokenFn, Tokens, User } from './interfaces';

/**
 * Convert response object to tokens
 *
 * Note: Expiration time has a small amount of time subtracted to
 * account for possible network latency.
 *
 * @param response Response from auth provider
 * @returns {Tokens}
 */
export function convertResponseToTokens(response: any): Tokens {
  const expiresIn = parseInt(response.expires_in || '-1', 10);

  return {
    accessToken: response.access_token || 'unset',
    idToken: response.id_token || 'unset',
    refreshToken: response.refresh_token || null,
    expires: Date.now() + expiresIn * 1000 - 5000, // -5s for network latency
    referer: response.state || '/',
  };
}

/**
 * Test if location contains a code
 *
 * @param location Window location
 * @returns {boolean}
 */
export function locationContainsCode(location: Location): boolean {
  const params = new URLSearchParams(location.search);

  return params.has('code');
}

/**
 * Extract code data from location
 *
 * @param location Window location
 */
export function extractCodeFromLocation(location: Location) {
  const params = new URLSearchParams(location.search);
  const state = params.get('state') || '/';

  return {
    code: params.get('code') || null,
    state: state.includes('callback') ? '/' : state,
  };
}

/**
 * Pull local tokens from browser storage
 *
 * Will resolve to null if no tokens are present in the
 * provided window store.
 *
 * @param store Browser storage
 * @returns {Tokens|null}
 */
export function readTokensFromStorage(store: Storage): Tokens | null {
  const localTokens = store.getItem('tokens');

  if (!localTokens) {
    return null;
  }

  return JSON.parse(localTokens);
}

/**
 * Save tokens to browser storage
 *
 * @param store Browser storage
 * @param tokens Tokens to sve
 * @returns {void}
 */
export function saveTokensToStorage(store: Storage, tokens: Tokens) {
  return store.setItem('tokens', JSON.stringify(tokens));
}

/**
 * Clear tokens (or everything) from browser storage
 *
 * @param store Browser storage
 * @param clear Whether to clear all storage
 */
export function clearTokensFromStorage(store: Storage, clear = false) {
  if (clear) {
    store.clear();
  } else {
    store.removeItem('tokens');
    store.removeItem('code-challenge');
    store.removeItem('code-verifier');
  }
}

/**
 * Test tokens for deep equality
 *
 * @param a Left token
 * @param b Right token
 * @returns {boolean}
 */
export function areTokensSame(a: Tokens | null, b: Tokens | null): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

/**
 * Test if token freshness
 *
 * Returns information relevant to token freshness.
 *
 * @param tokens Tokens to test for freshness
 * @param warningPeriod Token refresh warning window
 * @param inactivityPeriod Allowed period of inactivity
 */
export function evaluateTokenFreshness(
  tokens: Tokens | null,
  warningPeriod: number,
  inactivityPeriod: number
) {
  const now = Date.now();
  const expires = tokens ? tokens.expires : 1;
  const deadline = expires + inactivityPeriod;
  const expired = now > expires;
  const aboutToExpire =
    tokens !== null ? tokens.expires - now < warningPeriod : true;
  const passedDeadline = now > deadline;

  return {
    now,
    deadline,
    expires,
    expired,
    aboutToExpire,
    passedDeadline,
  };
}

interface ExtractedToken {
  amv?: string[];
  apisecret?: string;
  appproperties?: string[];
  at_hash?: string;
  aud?: string;
  azp?: string;
  domoid?: string;
  email?: string;
  exp: number;
  family_name?: string;
  given_name?: string;
  groups?: string[];
  iat: number;
  iss: string;
  nonce?: string;
  sid?: string;
  sub: string;
}

function extractProperties(properties: string[] = []) {
  if (!Boolean(properties)) {
    return {};
  }

  return properties.reduce((acc, cur) => {
    const [key, value] = cur.split(':');
    acc[key] = value;
    return acc;
  }, {} as { [index: string]: string });
}

/**
 * Pull user information from tokens
 *
 * @param tokens Tokens to extract
 * @returns {Tokens|null}
 */
export function extractUserFromTokens(tokens: Tokens | null): User | null {
  if (tokens === null || !tokens.accessToken) {
    return null;
  }

  const token = tokens.idToken || tokens.accessToken;

  try {
    const extracted = decode(token) as ExtractedToken;
    const props = extractProperties(extracted.appproperties);
    const subscriptions = Boolean(props?.subscriptionIds)
      ? props.subscriptionIds.split('|')
      : [];
    const entitlements = Boolean(props?.entitlementIds)
      ? props.entitlementIds.split('|')
      : [];
    const roles = Boolean(props?.productRoles)
      ? props.productRoles.split('|')
      : [];

    return {
      id: extracted.domoid,
      domoid: extracted.domoid,
      email: extracted.email,
      entitlements,
      familyName: extracted.family_name,
      givenName: extracted.given_name,
      groups: extracted.groups,
      properties: props,
      roles,
      sub: extracted.sub,
      subscriptions,
    };
  } catch (e) {
    return null;
  }
}

const sha256 = (buffer: string) =>
  crypto.createHash('sha256').update(buffer).digest();

const base64URLEncode = (str: Buffer) =>
  str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

/**
 * Create a new, random code verification string
 */
export function createCodeVerifier() {
  return base64URLEncode(crypto.randomBytes(32));
}

/**
 * Create a new code challenge string from verifier
 *
 * @param verifier Code verifier string
 */
export function createCodeChallenge(verifier: string) {
  return base64URLEncode(sha256(verifier));
}

/**
 * Get or create a new code/verifier pair
 *
 * @param store Window storage
 */
export function getOrGeneratePkceChallenge(store: Storage) {
  const challenge = store.getItem('code-challenge');
  const verifier = store.getItem('code-verifier');

  if (challenge && verifier) {
    return { challenge, verifier };
  }

  const codeVerifier = createCodeVerifier();
  const codeChallenge = createCodeChallenge(codeVerifier);

  store.setItem('code-challenge', codeChallenge);
  store.setItem('code-verifier', codeVerifier);

  return {
    challenge: codeChallenge,
    verifier: codeVerifier,
  };
}

export function createAuthLink(
  tokens: Tokens | null,
  expiringSoon: boolean,
  login: LoginFn,
  refreshToken: RefreshTokenFn,
  env: Environment
) {
  return from([
    // Handle downstream service 401 responses. This indicates that the token has
    // been revoked. There's no way for us to know this until we attempt to use the
    // token. If any GQL resolver throws an UNAUTHENTICATED code, we know we have
    // an unusable token.
    onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (err.extensions?.code === 'UNAUTHENTICATED') {
            login(true);
          }
        }
      }
    }),

    // We need to check if the token needs to be refreshed prior to sending a GQL
    // request. If any part of this process fails, the user must exit.
    new TokenRefreshLink({
      isTokenValidOrUndefined: () => {
        return !expiringSoon || typeof tokens?.accessToken !== 'string';
      },
      fetchAccessToken: () =>
        fetch(env.tokenRefreshURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: tokens ? `Bearer ${tokens.accessToken}` : '',
          },
          body: JSON.stringify({ refreshToken: tokens?.refreshToken }),
        }).then((x) => x.json()),
      handleFetch: () => {},
      handleResponse: () => (response: any) => {
        if (response.access_token) {
          refreshToken(convertResponseToTokens(response));
          return response;
        }
      },
      handleError: () => {
        login(true);
      },
    } as TokenRefreshLink.Options<string>) as any,
  ]);
}
