import getIntervalByGrain from './get-interval-by-grain';
import { isAnalysisInterval } from './is-analysis-interval';

const GRAIN_TO_DATE_PERIOD_MAP = new Map<Bera.DateInterval, Bera.DatePeriod>([
  ['weekly', '-8W'],
  ['monthly', '-9M'],
  ['quarterly', '-8Q'],
  ['yearly', '-5Y'],
]);

const DEFAULT: Bera.DatePeriod = '-5Y';

export default function getDefaultDatePeriod(
  grainOrInterval: Bera.DateGrain | Bera.DateInterval
) {
  const interval = isAnalysisInterval(grainOrInterval)
    ? grainOrInterval
    : getIntervalByGrain(grainOrInterval);
  const period = GRAIN_TO_DATE_PERIOD_MAP.get(interval) || DEFAULT;
  return period;
}
