import { useSessionStorage } from 'react-use';

import getEnvironment, {
  ValidEnvironment as UtilValidEnv,
} from 'utils/environment';

/**
 * List of valid environments
 */
export type ValidEnvironment = UtilValidEnv;

/**
 * Default environment is whatever NODE_ENV is set to
 */
const env = getEnvironment(
  process.env,
  process.env.NODE_ENV as ValidEnvironment
);

/**
 * Use process environment hook
 *
 * @param forceEnv Force load a select environment
 */
export default function useEnvironment(forceEnv?: ValidEnvironment) {
  const activeEnv = process.env.NODE_ENV as ValidEnvironment;
  const [localEnv] = useSessionStorage<ValidEnvironment | undefined>(
    'lp-force-environment',
    forceEnv || activeEnv,
    true
  );

  return localEnv ? getEnvironment(process.env, localEnv) : env;
}
