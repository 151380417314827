import React, { useMemo, useRef, useState } from 'react';

import { useLocalStorage } from 'react-use';

import getDefaultState from 'utils/global-data/get-default-state';
import serialize from 'utils/global-data/serialize';
import unserialize from 'utils/global-data/unserialize';

import GlobalDataContext, { GlobalDataState } from './context';
import Updater from './updater';

export interface GlobalStateProviderProps {
  persist?: boolean;
  persistenceKey?: string;
}

const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({
  persist = false,
  persistenceKey = 'global-data',
  children,
}) => {
  const [revision, setRevision] = useState(0);
  const [gds, setGds] =
    useLocalStorage<Bera.GlobalSerializedState>(persistenceKey);
  const ref = useRef(
    new Updater(
      gds && persist ? unserialize(gds) : getDefaultState(),
      (data, queue = false) => {
        setRevision(Date.now());
        if (!queue && persist) {
          setGds(serialize(data));
        }
      }
    )
  );

  const value: GlobalDataState = useMemo(() => {
    const cur = ref.current;
    ref.current.revision = revision;

    return {
      state: cur.state,
      queue: cur.queue,
      commit: cur.commit,
      dequeue: cur.dequeue,
      dequeueByKey: cur.dequeueByKey,
      enqueue: cur.enqueue,
      isReady: cur.isReady,
      refresh: cur.refresh,
      reset: cur.reset,
      getAllIds: cur.getAllIds,
      getAllAudienceIds: cur.getAllAudienceIds,
      getPrimaryAudience: cur.getPrimaryAudience,
      replaceDataset: cur.replaceDataset,
      replaceSubscription: cur.replaceSubscription,
      setAnalysisPeriod: cur.setAnalysisPeriod,
      setAudience: cur.setAudience,
      setCompetitiveAudiences: cur.setCompetitiveAudiences,
      setCompetitiveSet: cur.setCompetitiveSet,
      setCountry: cur.setCountry,
      setDataset: cur.setDataset,
      setMode: cur.setMode,
      setPrimary: cur.setPrimary,
      setStudies: cur.setStudies,
      setSubscription: cur.setSubscription,
      swapPrimary: cur.swapPrimary,
      swapPrimaryAudience: cur.swapPrimaryAudience,
      toggleCompetitor: cur.toggleCompetitor,
      toggleAudienceCompetitor: cur.toggleAudienceCompetitor,
    };
  }, [revision]);

  return (
    <GlobalDataContext.Provider value={value}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalStateProvider;
