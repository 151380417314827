import { createContext } from 'react';

export interface LaunchpadContextState {
  loaded: boolean;
  loading: boolean;
  allowedApplications: Bera.Application[];
  applications: Bera.Application[];
  lastUsedApp: string | null;
  roles: string[];
  subscriptions: Bera.Subscription[];
  userInfo?: Bera.UserInfo & { theme: Bera.Theme | null };
  appSubscriptions: (...role: string[]) => Bera.Subscription[];
  // TODO - this should just be Dataset when study ids are removed from app
  datasets: (subscriptionId: string) => Bera.SubscriptionDataset[];
}

export type LaunchpadContextData = Pick<
  LaunchpadContextState,
  'loaded' | 'loading'
>;

const LaunchpadContext = createContext<LaunchpadContextState>({
  allowedApplications: [],
  applications: [],
  lastUsedApp: null,
  loaded: false,
  loading: false,
  roles: [],
  subscriptions: [],
  userInfo: undefined,
  appSubscriptions: () => [],
  datasets: () => [],
});

export default LaunchpadContext;
