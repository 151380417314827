export default function isSubscriptionDataset(
  x: any
): x is Bera.SubscriptionDataset {
  return (
    'id' in x &&
    'countryId' in x &&
    'countryCode' in x &&
    'label' in x &&
    'lowestGrain' in x &&
    'grainDates' in x &&
    'studies' in x
  );
}
