import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';

import Header from 'components/header/header';
import ToastContainer from 'components/notifier/toast-container';
import { useAuth } from 'contexts';
import useLaunchpad from 'contexts/launchpad';
import useRum from 'hooks/use-rum';

import AuthCallback from './auth-callback';
import NotFound from './errors/not-found';
import Home from './home';
import ProtectedRoute from './protected-route';

const B2bApp = React.lazy(() => import('./brand-to-business'));
const B2bcsApp = React.lazy(() => import('./cross-sectional'));
const BaApp = React.lazy(() => import('./brand-accelerator'));
const BangApp = React.lazy(() => import('apps/bang'));
const BeliteApp = React.lazy(() => import('apps/belite'));
const BotApp = React.lazy(() => import('apps/bot'));
const DetApp = React.lazy(() => import('./data-export-tool'));
const SemApp = React.lazy(() => import('./sem-builder'));
const SpApp = React.lazy(() => import('apps/sp'));
const UmApp = React.lazy(() => import('apps/um'));
const DeApp = React.lazy(() => import('apps/de'));

const useStyles = makeStyles(
  (theme) => {
    const headerHeight = theme.mixins.toolbar.minHeight;

    return {
      root: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        overflow: 'hidden',
      },
      header: {
        height: headerHeight,
        minHeight: headerHeight,
        maxHeight: headerHeight,
        width: '100vw',
      },
      main: {
        minHeight: `calc(100vh - ${headerHeight}px)`,
        maxHeight: `calc(100vh - ${headerHeight}px)`,
        overflow: 'hidden',
      },
    };
  },
  { name: 'LP-Page', meta: 'LP-Page', index: 900 }
);

export const Pages = () => {
  const classes = useStyles();
  const location = useLocation();
  const rum = useRum();
  const { logout, user } = useAuth();
  const [initialized, setInitialized] = useState(false);
  const { allowedApplications, userInfo, loaded } = useLaunchpad();
  const { identify } = useAnalytics();

  // This was the only place I could think to put this, there must be a
  // better home for it somewhere else. Just can't think of it. It's just
  // analytics and tracking initializations.
  useEffect(() => {
    if (!initialized && loaded && user) {
      if (rum && rum.setUser) {
        rum.setUser({
          id: user?.properties?.userId,
          email: user?.email,
        });
      }

      identify(user?.properties?.userId || user?.sub || '.anon', {
        id: user?.properties?.userId,
        accountId: user?.properties?.accountId,
        email: user?.email,
        firstName: user?.givenName,
        lastName: user?.familyName,
        username: user?.email,
        company: {
          id: user?.properties?.accountId,
          name: user?.properties?.accountName,
        },
      });

      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // We reverse this, only for now, because BANG and BA are so similar that
  // they cause conflicts.
  const activeOne = [...allowedApplications]
    .reverse()
    .find((a) => location.pathname.startsWith(a.url));

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header
          user={userInfo} // useLaunchpad
          activeApplication={activeOne} // use history
          applications={allowedApplications} // useLaunchpad
          onSignout={logout} // useAuth instead?
        />
        <ToastContainer />
      </div>
      <div className={classes.main}>
        <React.Suspense fallback={null}>
          <Switch>
            <Route exact path="/" component={Home} />
            <ProtectedRoute
              applicationId="ba"
              path="/brand-accelerator"
              component={BaApp}
            />
            <ProtectedRoute
              applicationId="bang"
              path="/brand-explorer"
              component={BangApp}
            />
            <ProtectedRoute
              applicationId="bot"
              path="/operations-tool"
              component={BotApp}
            />
            <ProtectedRoute
              applicationId="det"
              path="/data-export"
              component={DetApp}
            />
            <ProtectedRoute
              applicationId="dmob2b"
              path="/brand-to-business-tool"
              component={B2bApp}
            />
            <ProtectedRoute
              applicationId="dmob2bcs"
              path="/brand-to-business-cs-tool"
              component={B2bcsApp}
            />
            <ProtectedRoute
              applicationId="dmosem"
              path="/sem-builder"
              component={SemApp}
            />
            <ProtectedRoute
              applicationId="sp"
              path="/scenario-planner"
              component={SpApp}
            />
            <ProtectedRoute
              applicationId="um"
              path="/user-management"
              component={UmApp}
            />
            <ProtectedRoute
              applicationId="belite"
              path="/lite-brand-explorer"
              component={BeliteApp}
            />
            <ProtectedRoute
              applicationId="datadl"
              path="/data-downloader"
              component={DeApp}
            />
            <Route exact path="/callback" component={AuthCallback} />
            <Route component={NotFound} />
          </Switch>
        </React.Suspense>
      </div>
    </div>
  );
};
