import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ApplicationLogo from '../application/logo';

export interface HeaderBrandingProps {
  application?: Bera.Application;
  devMode?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'default',
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    marginLeft: theme.spacing(2),
  },
  dev: {
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.spacing(1.5),
    color: theme.palette.text.hint,
  },
}));

const HeaderBranding: React.FC<HeaderBrandingProps> = ({
  application,
  devMode = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['products']);

  return (
    <div className={classes.root}>
      <ApplicationLogo application={application} size={24} />
      <div className={classes.title}>
        {t(`products:${application?.id || 'unassigned'}.name`)}
      </div>
      {devMode && <div className={classes.dev}>developer mode</div>}
    </div>
  );
};

export default HeaderBranding;
