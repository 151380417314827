import React from 'react';

import NorthStarIcon from 'icons/north-star';

import './north-star.css';

const NorthStar: React.FC = () => {
  return (
    <div className="north-star-loader">
      <NorthStarIcon height="6rem" width="6rem" />
    </div>
  );
};

export default NorthStar;
