/**
 * Remove an item from an array by reference
 *
 * @param arr Input array
 * @param item Item to remove from array
 */
function removeArrayItem<T = any>(arr: T[], item: T) {
  return arr.filter((i) => i !== item);
}

export default removeArrayItem;
