import React, { useCallback, useEffect, useState } from 'react';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Unknown } from 'pages/errors/unknown';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';

import ErrorBoundary from 'components/error-boundaries/error-boundary';
import useEnvironment, { ValidEnvironment } from 'hooks/use-environment';
import { Environment } from 'utils/environment';

import analytics from './analytics';
import { AuthProvider, createAuthLink, useAuth } from './auth';
import LaunchpadProvider from './launchpad/provider';
import useNotifier from './notifier';
import { Theme } from './theme';
import ZendeskProvider from './zendesk/provider';

type LocalClient = ApolloClient<NormalizedCacheObject>;

export interface ContextProps {
  forceEnvironment?: ValidEnvironment;
}

export interface InternalContextProps {
  env: Environment;
}

function offsetLimitPagination() {
  let lastSearch = '';
  let lasSubscription = '';

  return {
    keyArgs: [],
    merge(existing: any, incoming: any, params: any) {
      const currentSearch = params?.args?.query?.search || lastSearch;
      const currentSubscription =
        params?.args?.query?.subscription || lasSubscription;
      const merged =
        existing &&
        currentSearch === lastSearch &&
        lasSubscription === currentSubscription
          ? existing.slice(0)
          : [];
      const offset = params?.args?.query?.offset || 0;

      for (let i = 0; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
      }

      lastSearch = currentSearch;

      return merged;
    },
  };
}

const InternalContextProviders: React.FC<InternalContextProps> = ({
  children,
  env,
}) => {
  const { authenticated, tokens, login, refreshToken, expiringSoon } =
    useAuth();
  const [client, setClient] = useState<LocalClient | null>(null);
  const { notify } = useNotifier();

  // Setup apollo client
  useEffect(() => {
    if (!authenticated) {
      return;
    }

    const fields = {
      userInfo: { merge: true },
      authenticated: {
        read() {
          return authenticated;
        },
      },
      searchBrandscape: offsetLimitPagination(),
    };

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields,
        },
        ActiveAudienceCountry: {
          keyFields: false,
        },
        PositionMetricEntry: {
          keyFields: false,
        },
        PositionMetricValue: {
          keyFields: false,
        },
        ActiveSubscriptionDataset: {
          keyFields: false,
        },
        CMetric: {
          keyFields: false,
        },
        WhoAreCrosstabTheyValue: {
          keyFields: false,
        },
      },
    });

    setClient(
      new ApolloClient({
        cache,
        queryDeduplication: true,
        connectToDevTools: env.environment === 'development',
        link: ApolloLink.from([
          createAuthLink(tokens, expiringSoon, login, refreshToken, env),
          onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
              graphQLErrors.forEach(({ message }) => {
                notify({
                  channel: 'toast',
                  message,
                  severity: 'warning',
                  expires: Date.now() + 10000,
                });
              });
            }

            if (networkError) {
              console.error(networkError);
            }
          }),
          new HttpLink({
            uri: env.graphqlServerURL,
            headers: {
              authorization: tokens ? `Bearer ${tokens.accessToken}` : '',
            },
          }),
        ]),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, tokens, expiringSoon]);

  return (
    <React.Fragment>
      {client ? (
        <ApolloProvider client={client}>
          <AnalyticsProvider instance={analytics}>
            <LaunchpadProvider>
              <Theme>{children}</Theme>
            </LaunchpadProvider>
          </AnalyticsProvider>
        </ApolloProvider>
      ) : null}
    </React.Fragment>
  );
};

const helmetContext = {};

export const ContextProviders: React.FC<ContextProps> = ({
  children,
  forceEnvironment,
}) => {
  const env = useEnvironment(forceEnvironment);
  const handleLogout = useCallback(() => {
    if (window && window.localStorage) {
      window.localStorage.clear();
    }

    if (window && window.sessionStorage) {
      window.sessionStorage.clear();
    }
  }, []);

  return (
    <Router>
      <AuthProvider
        authority={env.iamServerUrl}
        clientId={env.iamClientId}
        inactivityPeriod={env.inactivityPeriod}
        location={window.location}
        logoutUri="/oidc/logout"
        redirectUri="/oauth2/authorize"
        tokenUri="/oauth2/token"
        onLogout={handleLogout}
      >
        <ErrorBoundary fallback={(<Unknown />) as any}>
          <HelmetProvider context={helmetContext}>
            <ZendeskProvider apiKey="e59395b2-14b7-4b08-b0b7-68a012fb98e1">
              <InternalContextProviders env={env}>
                {children}
              </InternalContextProviders>
            </ZendeskProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </AuthProvider>
    </Router>
  );
};

export default ContextProviders;
