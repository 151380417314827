/**
 * Get the first characters of each word in a string
 *
 * @param str Input string
 * @returns First character of each word
 */
export default function firstChars(str: string) {
  return str
    .split(' ')
    .map((s) => s.charAt(0))
    .join('');
}
