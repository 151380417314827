import { useMemo } from 'react';

interface RUM {
  init: (config: any) => void;
  addAction: (action: string, payload: any) => void;
  removeUser: () => void;
  setUser: (user: any) => void;
  addRumGlobalContext: (key: string, value: any) => void;
  setRumGlobalContext: (context: { [index: string]: any }) => void;
  getRumGlobalContext: () => { [index: string]: any };
}

interface RumWindowAddendum extends Window {
  DD_RUM?: RUM;
}

export default function useRum() {
  return useMemo(() => {
    const win: RumWindowAddendum = window as any;
    const msg = 'Datadog RUM missing, skipping';

    const fn = (...args: any[]) => {
      console.warn(msg, ...args);
    };

    const ctxFn = (...args: any[]) => {
      console.warn(msg, ...args);
      return {};
    };

    return (win.DD_RUM || {
      init: fn,
      addAction: fn,
      removeUser: fn,
      setUser: fn,
      addRumGlobalContext: fn,
      setRumGlobalContext: fn,
      getRumGlobalContext: ctxFn,
    }) as RUM;
  }, []);
}
