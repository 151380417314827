const INTERVAL_TO_DATE_PERIOD_MAP = new Map<Bera.DateGrain, Bera.DateInterval>([
  ['weekly', 'weekly'],
  ['monthly', 'monthly'],
  ['quarterly', 'quarterly'],
  ['annually', 'yearly'],
]);

const DEFAULT: Bera.DateInterval = 'yearly';

export default function getIntervalByGrain(grain: Bera.DateGrain) {
  const interval = INTERVAL_TO_DATE_PERIOD_MAP.get(grain) || DEFAULT;
  return interval;
}
