import { createContext } from 'react';

import getDefaultState from 'utils/global-data/get-default-state';
import noop from 'utils/noop';

export interface GlobalDataState {
  state: Bera.GlobalState;
  queue: Bera.GlobalState;
  commit: (callback?: Function) => void;
  dequeue: () => void;
  dequeueByKey: (keys: Array<keyof Bera.GlobalState>) => void;
  enqueue: (state: Partial<Bera.GlobalState>) => void;
  isReady: (queue?: boolean, skip?: (keyof Bera.GlobalState)[]) => boolean;
  refresh: () => void;
  reset: () => void;
  getAllIds: (prefix?: string, queue?: boolean) => string[];
  replaceDataset: (config: {
    dataset: Bera.Dataset | Bera.SubscriptionDataset;
    subscription?: Bera.Subscription;
    queue?: boolean;
  }) => void;
  getAllAudienceIds: (config: {
    queue?: boolean;
    format?: 'full' | 'default';
  }) => string[];
  getPrimaryAudience: (config: {
    format?: 'full' | 'default';
    queue?: boolean;
  }) => string;
  replaceSubscription: (
    subscription: Bera.Subscription,
    queue?: boolean
  ) => void;
  setAnalysisPeriod: (args: Bera.AnalysisPeriodArgs, queue?: boolean) => void;
  setAudience: (audience?: string, queue?: boolean) => void;
  setCompetitiveAudiences: (audiences: string[], queue?: boolean) => void;
  setCompetitiveSet: (competitiveSet: string[], queue?: boolean) => void;
  setCountry: (
    country: string,
    code: Bera.Country.Code,
    queue?: boolean
  ) => void;
  setDataset: (dataset: string, queue?: boolean) => void;
  setMode: (mode: Bera.GlobalState['mode'], queue?: boolean) => void;
  setPrimary: (brand?: string, queue?: boolean) => void;
  setStudies: (studies: string[], queue?: boolean) => void;
  setSubscription: (subscription: string, queue?: boolean) => void;
  swapPrimary: (primary: string, queue?: boolean) => void;
  swapPrimaryAudience: (audience: string, queue?: boolean) => void;
  toggleCompetitor: (
    competitor: string,
    position?: number,
    queue?: boolean
  ) => void;
  toggleAudienceCompetitor: (
    competitor: string,
    position?: number,
    queue?: boolean
  ) => void;
}

const GlobalDataContext = createContext<GlobalDataState>({
  state: getDefaultState(),
  queue: getDefaultState(),
  commit: noop,
  dequeue: noop,
  dequeueByKey: noop,
  enqueue: noop,
  isReady: () => false,
  refresh: noop,
  reset: noop,
  getAllIds: () => [],
  getAllAudienceIds: () => [],
  getPrimaryAudience: () => '',
  replaceDataset: noop,
  replaceSubscription: noop,
  setAnalysisPeriod: noop,
  setAudience: noop,
  setCompetitiveAudiences: noop,
  setCompetitiveSet: noop,
  setCountry: noop,
  setDataset: noop,
  setMode: noop,
  setPrimary: noop,
  swapPrimaryAudience: noop,
  setStudies: noop,
  setSubscription: noop,
  swapPrimary: noop,
  toggleCompetitor: noop,
  toggleAudienceCompetitor: noop,
});

export default GlobalDataContext;
