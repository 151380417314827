const DEFAULT_URL = '/brand-explorer';
const DEFAULT_APPS = ['bang', 'sp', 'ba', 'det'];

/**
 * Determine default fallback application
 *
 * @param apps List of allowed applications
 * @param lastApp Possible last used application path
 */
export default function determineDefaultApp(
  apps: Bera.Application[],
  lastApp: string | null
) {
  if (lastApp) {
    return lastApp;
  }

  let defaultApp = apps[0];

  if (apps.length > 1) {
    for (const app of DEFAULT_APPS) {
      const found = apps.find((a) => a.id === app);

      if (found) {
        defaultApp = found;
        break;
      }
    }
  }

  return defaultApp?.url || DEFAULT_URL;
}
