function isPresent(app: Bera.Application, b: string[]) {
  return app.iamRoles.filter((ir) => b.includes(ir)).length > 0;
}

/**
 * Find app by a given URL segment
 *
 * @param apps Application list
 * @param segment URL segment
 * @returns {Application|undefined}
 */
export function findAppByUrlSegment(apps: Bera.Application[], segment: string) {
  if (!segment) {
    return;
  }

  return apps.find((app) => app.url.includes(segment));
}

/**
 * Find allowed apps for a given list of roles
 *
 * @param apps Application list
 * @param roles Allowed roles list
 * @returns {Application[]}
 */
export function findAllowedApps(apps: Bera.Application[], roles: string[]) {
  return apps.filter((app) => isPresent(app, roles));
}

/**
 * Convert applications to links
 *
 * @param apps Application list
 * @todo Add icon implementation when final
 */
export function convertAppsToLinks(apps: Bera.Application[]) {
  return apps.map((app) => ({
    to: app.url,
    logo: app.logo,
    label: app.name,
  }));
}
